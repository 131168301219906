<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="179 307 100.25 87"
    style="enable-background:new 0 0 537.5 273;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M263.4,363.3c0,0,11.6-0.1,14.6-9.4c3-9.3-1.6-15.4-3.2-17.6c-1.6-2.3-5-6.1-5.5-6.3   s-1.9-2.4-2.6-3.6s-1.4-2.6-2.1-3c-0.6-0.4-9.1,5.3-9.1,5.3l-18,18.9l-15.8,17.2l4.7,3.7c0,0,2-0.5,3.1-1c1-0.4,6.1-2.8,7-2.8   c0.9,0,8.1,0,9.9-0.1c1.8-0.1,4.4-0.4,6.2-0.4C254.3,364.2,262.7,363.4,263.4,363.3z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M264.6,323.3c0,0-1.4,0.2,2-8.5c0.6-3.9-1.7-8.2-3.6-7.9c-2.1,0.5-6,0.4-7,2.7   c-2.8,0.9-8,2.3-8,2.3l3.4,15.7c0,0-1.3,1.1-2.1,2.3c-0.8,1.2-2.9,2.8-4.6,3.1c-1.7,0.6-2.8,0.9-4.4,0.3c-1.6-0.6-3.3-1.2-3.3-1.2   l-7.5-5.9c0,0,2.7-1.2,3.7-1.9s1.1-2.2,0.4-2.8c-0.7-0.6-1.8-1.4-1.8-1.4s-1.7-0.7-2.8,0.7s-1.7,2.8-1.7,2.8s-0.4,1-1.3,0.4   s-1.6,0.5-1.6,0.5s-1.1-0.1-1.3-0.4s-1.2-1-1.2-1l-6.5,8.6c0,0,0.6,1.2,1,1.3c-0.3,0.6-0.3,0.6-0.3,0.6l0.5,0.6   c0,0-2.6,2.7-3.2,3.4s-8.6,7-12.6,13.7c-2.2,2.7-4.6,5.1-5.1,6.7s-2.9,5.3-3.8,6.2c-0.9,1-2.1,3.1-2.7,3.7s-5.3,6.1-6,10.6   c-0.4,3-0.3,5.4,0.5,9s2.4,6.2,2.7,6.3c0.3,0.1,2.3,0.4,3.3-0.4c1.1-0.8,15-12.7,20.2-15.2c5.2-2.4,11.8-6,12.6-6.7   c0.8-0.7,7.2-5.9,10.1-8.9c3-3,4.2-5.2,5.1-5.5c0.9-0.3,1.4-0.8,1.6-1.6c0.2-0.8,9.6-11.7,12.8-15.6S263.1,328.7,264.6,323.3z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M237.1,335.6c0,0-12.2-9.1-13-10.3s-2-2.2-2-2.2l-6.5,8.6c0,0,8,8.9,10.8,10.5   C229.1,343.6,237.1,335.6,237.1,335.6z"
      />
    </g>
  </svg>
</template>

