<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 99.5 40.2"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
	    <path class="svg-shoes-sole-fill" d="M96.8,25.8c0,0,0.9,0,0.7,1.4s0.7,4.1,1.4,5c0.7,0.9,1.3,3.4-0.5,5.1c-1.5,0.9-7,2.4-8.4,2.5 c-0.6,0-0.6,0-0.6,0l-0.4-0.8h-1.4l-0.7,1.1h-2.4l-0.2-0.9l-0.5,0l-0.1,0.9h-1.7l-0.9-1.4h-1.2l-0.6,1.4h-3.9l-0.8-1.4h-0.7 L73,39.9c0,0-1.9,0.1-2.6-0.2s-1.9-1.9-1.9-1.9l-0.7-0.1l-0.4,0.9c0,0-0.3,0.3-1.5,0.3c-1.3,0-2.5,0-2.5,0l-0.6-1.4 c0,0-9.6-0.3-11.8,0.2c0,0.5,0,0.8,0,0.8s-0.7,0.6-2.1,0.6c-1.5,0-2.1,0-2.2-0.2c-0.1-0.2-0.3-0.7-0.3-0.7l-2.7,0.6v0.9L39.7,40 l-0.2-0.7l-1.9,0c0,0-0.7,0.5-2.4,0.6c-1.7,0.1-3.5-0.5-3.5-0.5l-2.3-0.2l-0.8,0.7l-2.1,0.1c0,0-2.6,0-3.6-0.2 c-1-0.1-4.4-0.6-5-0.9c-0.6-0.2-1.2-1.2-1.7-1.3s-0.9,0.7-0.9,0.7s-3.3-0.8-3.9-1.3c-0.6-0.5-1.3-1.5-1.3-1.5l-0.8,0.7 c0,0-4.6-2.1-6.2-3s-2.8-1.8-3-2.2C0,30.5,0,28.9,0,28.9s0-0.9,0.5-1c0.1-2.4,0-5.9,1.9-7.2c2.2-0.8,4.5-0.8,4.5-0.8L96.8,25.8z"/>
      <path class="svg-shoes-color-fill" d="M92.3,6.2c0,0-1.5-2.3-4.2-1.6c-2.7,0.7-3.3,2.1-3.8,3c-0.5,0.9-2.8,4.1-6.9,3.5 c-4.2-0.7-4.7-0.1-9.8-2.3c0.3-1.6,0.1-6-0.4-7.4c-0.5-1.4-1.3-1.4-2.6-1.1c-1.3,0.3-8.8,4.5-8.8,4.5S55,3.5,53.4,3.8 c-1.6,0.4-1.9,1.1-2.2,1.8c-1,0.4-3.9,2-4.5,2.2c-0.6,0.2-1.5-0.2-2.3,0.5s-1.1,1.5-2.1,1.7c-1,0.2-2.8-0.4-3.3,0.3 c-0.5,0.7-0.1,1.2-0.9,1.6c-0.8,0.4-1.4,1-2.1,1s-2.6-0.6-3.3,1c-0.6,0.8-1.3,0.9-2.1,1.1s-7.7,2.1-10.3,2.6s-6.3,1.9-9.2,1.9 c-2.9,0.1-5.2,0.6-4.4,1.6c1.3,1,3.8,2.1,7,3.3c3.2,1.2,7.5,2.2,10.4,3.4c2.9,1.2,7.3,2.7,9.1,3.7c1.8,1,4.8,2.2,7,3.6 c1.6,1.2,2.5,2.5,2.5,2.5s13-2.5,21.9-1.2c-2.8-2.3-4.7-5.8-7-6.5c4.3-0.6,15.7-1.5,20.9-1.7c5.2-0.2,12.2-0.5,13.8-0.7 c1.6-0.3,3.9-0.5,4.5-1.8c0.6-1.3,1.2-3.9,0.8-7.5C97.1,16,95,9.1,92.3,6.2z"/>
      <path class="svg-shoes-lace-fill" d="M56.4,8.3c0,0-0.8-2.1-1.6-2.9c-0.8-0.8-1.3-0.9-2.2-0.8C52.2,4.9,52,5.2,52,5.2l0.1,0.3 c0,0,1.7-0.5,2.2,0.7c0.5,1.2,0.7,2.8,1.2,2.7C56,8.8,56.4,9,56.4,8.3z"/>
      <polygon class="svg-shoes-lace-fill" points="45,8.3 45.4,10.3 44.8,10.5 44.4,8.6"/>
      <g xmlns="http://www.w3.org/2000/svg">
        <clipPath id="approach-shoe-lace-clip-1">
          <path style="overflow:visible;" d="M55.3,5.3c0,0-0.7-1.4-1.7-1c-1,0.3-1.2,0.2-1.6,1.8c-0.6,0.1-0.8,0.1-0.8,0.1l-0.5-3.3l4.2-0.6l0.9,1.1 l-0.1,1.4L55.3,5.3z"/>
        </clipPath>
        <path class="st2" d="M92.2,6.2c0,0-1.5-2.3-4.2-1.6c-2.7,0.7-3.3,2.1-3.8,3c-0.5,0.9-2.8,4.1-6.9,3.5 c-4.2-0.7-4.7-0.1-9.8-2.3c0.3-1.6,0.1-6-0.4-7.4c-0.5-1.4-1.3-1.4-2.6-1.1c-1.3,0.3-8.8,4.5-8.8,4.5s-0.7-1.2-2.3-0.9 c-1.6,0.4-1.9,1.1-2.2,1.8c-1,0.4-3.9,2-4.5,2.2c-0.6,0.2-1.5-0.2-2.3,0.5C43.3,9,43.1,9.7,42.1,10c-1,0.2-2.8-0.4-3.3,0.3 c-0.5,0.7-0.1,1.2-0.9,1.6s-1.4,1-2.1,1s-2.6-0.6-3.3,1c-0.6,0.8-1.3,0.9-2.1,1.1c-0.8,0.2-7.7,2.1-10.3,2.6s-6.3,1.9-9.2,1.9 s-5.2,0.6-4.4,1.6c1.3,1,3.8,2.1,7,3.3c3.2,1.2,7.5,2.2,10.4,3.4c2.9,1.2,7.3,2.7,9.1,3.7c1.8,1,4.8,2.2,7,3.6 c1.6,1.2,2.5,2.5,2.5,2.5s13-2.5,21.9-1.2c-2.8-2.3-4.7-5.8-7-6.5c4.3-0.6,15.7-1.5,20.9-1.7c5.2-0.2,12.2-0.5,13.8-0.7 c1.6-0.3,3.9-0.5,4.5-1.8c0.6-1.3,1.2-3.9,0.8-7.5C97,16.1,94.9,9.1,92.2,6.2z"/>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <clipPath id="approach-shoe-lace-clip-2">
          <path style="overflow:visible;" d="M46.2,8.4c0,0-1.5-0.2-2.4,1.4c-1-0.1-0.9-0.5-0.9-0.5L45,6.8l1.3,0.7L46.2,8.4z"/>
        </clipPath>
        <path class="st3" d="M92.2,6.2c0,0-1.5-2.3-4.2-1.6c-2.7,0.7-3.3,2.1-3.8,3c-0.5,0.9-2.8,4.1-6.9,3.5 c-4.2-0.7-4.7-0.1-9.8-2.3c0.3-1.6,0.1-6-0.4-7.4c-0.5-1.4-1.3-1.4-2.6-1.1c-1.3,0.3-8.8,4.5-8.8,4.5s-0.7-1.2-2.3-0.9 c-1.6,0.4-1.9,1.1-2.2,1.8c-1,0.4-3.9,2-4.5,2.2c-0.6,0.2-1.5-0.2-2.3,0.5C43.3,9,43.1,9.7,42.1,10c-1,0.2-2.8-0.4-3.3,0.3 c-0.5,0.7-0.1,1.2-0.9,1.6s-1.4,1-2.1,1s-2.6-0.6-3.3,1c-0.6,0.8-1.3,0.9-2.1,1.1c-0.8,0.2-7.7,2.1-10.3,2.6s-6.3,1.9-9.2,1.9 s-5.2,0.6-4.4,1.6c1.3,1,3.8,2.1,7,3.3c3.2,1.2,7.5,2.2,10.4,3.4c2.9,1.2,7.3,2.7,9.1,3.7c1.8,1,4.8,2.2,7,3.6 c1.6,1.2,2.5,2.5,2.5,2.5s13-2.5,21.9-1.2c-2.8-2.3-4.7-5.8-7-6.5c4.3-0.6,15.7-1.5,20.9-1.7c5.2-0.2,12.2-0.5,13.8-0.7 c1.6-0.3,3.9-0.5,4.5-1.8c0.6-1.3,1.2-3.9,0.8-7.5C97,16.1,94.9,9.1,92.2,6.2z"/>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <clipPath id="approach-shoe-lace-clip-3">
          <path style="overflow:visible;" d="M41,10.9c0,0-1-0.8-1.4,0c-0.4,0.7-0.4,1.3-0.4,1.3l-1.6-0.5l1.3-2.5l2.4-0.2l0.2,1.1L41,10.9z"/>
        </clipPath>
        <path class="st4" d="M92.2,6.2c0,0-1.5-2.3-4.2-1.6c-2.7,0.7-3.3,2.1-3.8,3c-0.5,0.9-2.8,4.1-6.9,3.5 c-4.2-0.7-4.7-0.1-9.8-2.3c0.3-1.6,0.1-6-0.4-7.4c-0.5-1.4-1.3-1.4-2.6-1.1c-1.3,0.3-8.8,4.5-8.8,4.5s-0.7-1.2-2.3-0.9 c-1.6,0.4-1.9,1.1-2.2,1.8c-1,0.4-3.9,2-4.5,2.2c-0.6,0.2-1.5-0.2-2.3,0.5C43.3,9,43.1,9.7,42.1,10c-1,0.2-2.8-0.4-3.3,0.3 c-0.5,0.7-0.1,1.2-0.9,1.6s-1.4,1-2.1,1s-2.6-0.6-3.3,1c-0.6,0.8-1.3,0.9-2.1,1.1c-0.8,0.2-7.7,2.1-10.3,2.6s-6.3,1.9-9.2,1.9 s-5.2,0.6-4.4,1.6c1.3,1,3.8,2.1,7,3.3c3.2,1.2,7.5,2.2,10.4,3.4c2.9,1.2,7.3,2.7,9.1,3.7c1.8,1,4.8,2.2,7,3.6 c1.6,1.2,2.5,2.5,2.5,2.5s13-2.5,21.9-1.2c-2.8-2.3-4.7-5.8-7-6.5c4.3-0.6,15.7-1.5,20.9-1.7c5.2-0.2,12.2-0.5,13.8-0.7 c1.6-0.3,3.9-0.5,4.5-1.8c0.6-1.3,1.2-3.9,0.8-7.5C97,16.1,94.9,9.1,92.2,6.2z"/>
      </g>
      <g xmlns="http://www.w3.org/2000/svg">
        <clipPath id="approach-shoe-lace-clip-4">
          <path style="overflow:visible;" d="M35,13.7c0,0-1.1-0.5-1.7,0.5s-0.6,1-0.6,1l-1.6-1.3l2.7-3.2l1.6,0.7L35,13.7z"/>
        </clipPath>
        <path class="st5" d="M92.2,6.2c0,0-1.5-2.3-4.2-1.6c-2.7,0.7-3.3,2.1-3.8,3c-0.5,0.9-2.8,4.1-6.9,3.5 c-4.2-0.7-4.7-0.1-9.8-2.3c0.3-1.6,0.1-6-0.4-7.4c-0.5-1.4-1.3-1.4-2.6-1.1c-1.3,0.3-8.8,4.5-8.8,4.5s-0.7-1.2-2.3-0.9 c-1.6,0.4-1.9,1.1-2.2,1.8c-1,0.4-3.9,2-4.5,2.2c-0.6,0.2-1.5-0.2-2.3,0.5s-1.1,1.5-2.1,1.7c-1,0.2-2.8-0.4-3.3,0.3 s-0.1,1.2-0.9,1.6c-0.8,0.4-1.4,1-2.1,1s-2.6-0.6-3.3,1c-0.6,0.8-1.3,0.9-2.1,1.1c-0.8,0.2-7.7,2.1-10.3,2.6s-6.3,1.9-9.2,1.9 s-5.2,0.6-4.4,1.6c1.3,1,3.8,2.1,7,3.3c3.2,1.2,7.5,2.2,10.4,3.4c2.9,1.2,7.3,2.7,9.1,3.7c1.8,1,4.8,2.2,7,3.6 c1.6,1.2,2.5,2.5,2.5,2.5s13-2.5,21.9-1.2c-2.8-2.3-4.7-5.8-7-6.5c4.3-0.6,15.7-1.5,20.9-1.7c5.2-0.2,12.2-0.5,13.8-0.7 c1.6-0.3,3.9-0.5,4.5-1.8c0.6-1.3,1.2-3.9,0.8-7.5C97,16.1,94.9,9.1,92.2,6.2z"/>
      </g>
    </g>
  </svg>
</template>

<style type="text/css" scoped>
  .st2{clip-path:url(#approach-shoe-lace-clip-1);fill:#4D4E4E;}
  .st3{clip-path:url(#approach-shoe-lace-clip-2);fill:#4D4E4E;}
  .st4{clip-path:url(#approach-shoe-lace-clip-3);fill:#4D4E4E;}
  .st5{clip-path:url(#approach-shoe-lace-clip-4);fill:#4D4E4E;}
</style>
