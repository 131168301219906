<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="29 30 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M127.2,75.9c0,0,0.7,1.3,1,3c0.3,1.7,2,4,2.3,4.6s1.4,1.7-0.4,3.8s-6.6,6.1-14.5,6.8   c-5.7,0.4-5.7,0.4-5.7,0.4l-1.7-1l-0.9,1.2c0,0-13.7,0.7-14.7,0.4c-1-0.4-2.2-1.2-2.2-1.2l-0.4,1c0,0-11,1.1-12,1.1   c-1.1,0-2.2-0.9-2.2-0.9L75.3,96c0,0-6.6,1.3-15.8-0.3c-0.9-0.9-1.4-1.5-1.4-1.5L57,95.3c0,0-12.2-2-18.4-5.9s-7.6-6.2-7.7-6.7   c-0.1-0.4,0-1.9,0-1.9l6.2-0.2L127.2,75.9z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M124.3,58.8c0,0,0.8-1.7,0.3-2.9s-1.9-0.8-2.9-0.2c-0.9,0.5-5.4,3.2-7.4,5   c-2,1.8-5.8,5.7-13.2,4.5C99.4,64.7,97,62,97,62s1.2-0.8,0.8-2.5s0.4-3.9,3.2-6c2.8-2.1,1.4-3.8,1-4s-1.5-0.7-2.9,0.2   c-1.3,0.9-8.1,4.3-9.7,5.1c-1.6,0.8-4,2.4-4,2.4s-2.2,0.6-4.1,2.6c-0.6,1-1.4,1.3-1.6,1.4c-0.2,0.1-1.9,1.2-2.3,1.5   c-0.4,0.3-0.7,0.5-1.6,0.6s-0.9,0.5-1.2,0.6c-0.2,0-1.6,0.4-2,0.7c-0.4,0.3-0.8,0.6-1.4,0.6s-1.3,0.2-1.8,0.6s-1.2,1.1-1.7,1.2   s-2,0.9-3.6,1.4c-1.6,0.6-2,1.3-6.6,2.1c-4.6,0.7-6.9,0.8-8.6,1.1c-1.7,0.3-4.2,0.9-6.5,1.5c-2.2,0.5-3.1,0.6-5.3,1.2   c-2.3,0.6-5.9,1.9-6.2,6.3c0.1,1,2.4,2.8,3.4,3c0.9,0.1,10.6,5,25.3,5.5c4,0.2,7.4,0.6,15.1-1.2c5.3-1,14-3.9,18.1-4.5   c4.1-0.6,10.8-1,20.6-0.6c8.1,0.3,9.7,0.7,11.8-0.8c2.1-1.5,1.7-4.6,1.9-6.1c0.2-1.5,0.4-4.9-1.1-8   C124.8,65.5,123.3,62.3,124.3,58.8z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M84.3,57.8c0,0,3.2-0.7,4.2,0.3s1.5,1.1,2.5,2.6c-0.6,0.4-0.9,0.4-0.9,0.4s-1.7-2-3.5-2   s-2.2,0-2.2,0V57.8z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M85.8,64c0,0-2.1-4.1-1.5-6.2c-0.9,0.5-1,0.6-1,0.6s-1.1,2.3,1,6.1C85.1,64.1,85.8,64,85.8,64z   "
      />
      <path
        class="svg-shoes-lace-fill"
        d="M80.7,60.8c0,0-0.9,3.7-0.5,6c-0.9,0.6-0.9,0.6-0.9,0.6s-1.2-2.6,0-5.8   C79.8,61.3,80.7,60.8,80.7,60.8z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M83.3,62.5c0,0-1.7-1.2-2.7-1.2c-0.1,0.7-0.2,1.2-0.2,1.2s1.8,0.1,2.2,0.8S83.3,62.5,83.3,62.5   z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M76.6,63.4c0,0-0.9,2.2-0.9,5.4c-1.3,0.6-1.5,0.5-1.5,0.5s0-3.9,1-5.5   C75.6,63.6,76.6,63.4,76.6,63.4z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M78.8,65.1c0,0-0.8-1.3-2.4-1.3c-0.4,0.9-0.3,1.3-0.3,1.3s2.1,0.2,2.5,1.2   c0.4,0.6,0.6,0.7,0.6,0.7S78.8,65.7,78.8,65.1z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M71.1,65.4c0,0-1.1,1.2-0.7,5.8c-1,0.7-1.3,0.8-1.3,0.8s-0.8-4-0.1-5.5   C69.8,65.8,70.2,65.5,71.1,65.4z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M73.4,66.9c0,0-0.8-1.4-2.5-1.3c-0.5,0.6-0.5,0.7-0.5,0.7s1.3,0.1,1.6,1.3   C73.1,67.3,73.4,66.9,73.4,66.9z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M66.5,67.6c0,0,0.1,1.7,2.1,3.2c0.4,0.3,0.4,0.3,0.4,0.3l-1.5,1.1c0,0-2.5-1.8-3-3.7   C65.5,68.1,66.5,67.6,66.5,67.6z"
      />
    </g>
  </svg>
</template>
