<template>
  <svg
    version="1.1"
    x="-5px"
    y="0px"
    viewBox="480 32 100.25 87"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-sole-fill" d="M576,77.5c0,0,0.5,1.8,0,3s0,2.6,2.3,4c2.3,1.4,3,5.7,2.7,7.4c-0.3,1.7-2.5,4.6-4.7,4.9   s-6.8,0.6-9.1,0.7c-2.3,0.1-16,0.7-19,0.7s-29.2,0.5-34.7,0c-5.5-0.5-21.5-3.3-27.3-7.2c-3.1-2-4.9-3.9-5.1-5s0.1-2.1,0.1-2.1   l19.9-1.9l51.4-10.6l13.5-3L576,77.5z"/>
      <path class="svg-shoes-color-fill" d="M568.7,56.8c0,0-1.3-1.3-1.7-2s-1.9-1.1-2.7,0.7s-0.8,1.6-1.1,3.5c-0.3,1.4-1.8,3.5-3.6,4.8   c-1.8,1.3-3.8,2.6-3.8,2.6s-10.6-5.2-11-5.5c-0.4-0.3-2.2-1.3-1.3-2.6s4-4.7,4.2-5.8c0.2-1.1-0.3-2.5-2.3-1.8   c-2,0.7-4.4,1.3-7.3,4.2c-0.9-0.3-2.1-0.4-2.3,1.5c-0.8,0.8-1,1.6-1.7,1.6c-0.7-0.1-2.2-0.8-3.1,1.2c-0.8,1.9-1,2-2.1,2   s-2.8,0-3.3,1.5s-1.2,1.6-2.1,1.8c-0.8,0.2-1.7,0.2-2.4,1.3s-1.6,1.6-2.2,1.8c-0.6,0.2-1.8,0.9-2.1,1.7s-1.5,0.9-1.6,1.1   s-1,1.2-1.3,1.5c-0.3,0.4-4.3,1.6-8.5,2.9s-17.2,4.8-18.5,5.4s-3.9,0.9-4.7,2c-0.6,1.1-2.3,3,0.3,3.9s13.1,3.1,19.5,3.9   s18.8,1,28.6-2.2s16.7-3.5,18.7-4.5c2-1,11.5-7.7,12.9-8.4c1.5-0.7,3-2.8,8.1,0c5.1,2.8,5.6,2.7,5.6,2.7s-1.9-4.6-2.1-5.1   c-0.2-0.5,0.5-1.2,0.4-1.7c-0.1-0.5-0.2-1.3-0.5-1.9s-1.2-1.6-1.4-2.1c-0.2-0.5-0.1-1.7-0.3-2.1c-0.2-0.4-0.5-1-0.3-2.3   c0.2-1.3-0.5-3-1-3.3c-0.5-0.3-1.2-0.9-1.3-1.3C569.4,57.3,568.7,56.8,568.7,56.8z"/>
      <path class="svg-shoes-lace-fill" d="M540,57.9l-2.1-3.1c0,0-2.3-0.5-2.1,1.5c1,1.1,3,3.7,3,3.7L540,57.9z"/>
      <path class="svg-shoes-lace-fill" d="M533.1,63.6l-2.7-3.3c0,0-0.4,1.3-3.2,0.9c2.7,2.8,3.3,3.7,3.3,3.7L533.1,63.6z"/>
      <path class="svg-shoes-lace-fill" d="M526.7,66.8l-2-2.8c0,0-2.7,0.5-3.2,1.2c1.6,2.2,2.4,3.2,2.4,3.2L526.7,66.8z"/>
      <path class="svg-shoes-lace-fill" d="M520.5,70.1l-1.6-2.7c0,0-2.3,1-2.3,2.1c0.7,1,1.4,1.8,1.4,1.8L520.5,70.1z"/>
    </g>
  </svg>
</template>

