<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="30 173 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M128.1,222.3c0,0,0.9,0,1,2.2c-0.1,1,0.2,2.1,1,2.8c0.8,0.7,1.4,2.5,0.7,4.8s-3.6,6.4-8.8,7.3   c-5.2,1-13.2,0.9-15.5,0.7c-2.2-0.2-5.8-1.6-6.8-2.2c-2.3,0-10.2,0.8-14.1,0.6c-4.8,2-7.6,3.6-12.3,3.4c-4.7-0.1-15.3-0.6-17.6-0.7   s-12.4-3.8-15.9-5.8c-3.5-2-6.7-4.2-8.4-6.2c-0.5-1-0.4-2.9,0-3.3c0.4-0.3,5.6-1.2,5.6-1.2l73.4-9.6L128.1,222.3z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M111.1,186.8c0,0,0.3-2.4-2.4-3.1c-2.7-0.7-4.8-1.2-7.4-1.9c-2.5-0.7-5.9-1.4-6.9,1.6   c-1,2.9-1,5.6-1,5.6s-3.4,1.2-2.4,4.5c-1.2,1.7-2.2,3.1-1.8,5.2c-1.9,1.1-4,2-3.5,5.1c-2.8,1.3-4.2,1.9-5,2s-2.1,0.8-2.4,1.4   c-0.3,0.7-0.9,0.9-1.1,0.9c-0.2,0-1.4,1.1-1.7,1.5c-1.4,0-4.4-0.5-6.2,1.2c-1.6,0.6-3.8,1.6-4.8,2.4c-1.6-0.1-3.6,0-4.3,0.5   s-1.2,0.8-2,0.8s-1.4,0.3-2.4,0.7c-1,0.4-4,1.9-10.1,2.4c-3.5,0.4-6.1,1.1-7.1,1.1c-0.9,0-5.6,0.1-6.6,2.9c-1,2.8-0.9,4.5-0.9,4.5   s4.1,2.3,7.9,2.6c3.7,0.4,10.4,1.1,13.3,1.1c1.6,0,4.7,1.1,6.3,2c1.6,0.9,3,2.1,7.5,0.7c4.4-1.5,9.3-4,11-4.8   c1.7-0.8,3.1-1.6,8.6-1.1c4.7,0.5,9.2,1.8,12.8-1c3.5-2.8,5.8-6.2,12-4.8c6.2,1.4,12.8,2.5,14.4,2.4c1.6-0.1,2.7-0.3,2.8-0.8   c-0.1-2.4,0.1-3.4-0.4-3.8c-0.4-0.4-0.6-0.9-0.1-1.7c0.5-0.8,0.2-2.6-0.5-3.2c-0.7-0.6-1-1.4-1-2.4s-0.5-1.9-0.9-2.1   c-0.4-0.2-0.9-0.6-0.1-1.5c0.8-0.9,0.4-2.3,0.1-2.8c-0.4-0.5-1.2-1.1-0.1-2.2c1.1-1.2,0.2-2.7,0-3.1c-0.3-0.4-0.4-0.9,0.2-1.4   c0.6-0.4-0.6-1.5-2.4-2.2C121,195.2,113,188.7,111.1,186.8z"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="101.4,186.9 92.4,189.7 91.2,191.2 100.9,188.7  "
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="95.3,197.4 92.2,190.6 91.2,191.2 94.3,198.8  "
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="86.7,200.6 93.9,197.8 94.3,198.8 86,201.9  "
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="87.8,205.3 87.8,201 86.7,201.3 86.7,205.9  "
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="81.8,205.6 86.7,204.9 86.7,205.9 80.9,206.7  "
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="79.3,210.8 81.8,205.6 80,205.9 77.8,210.6  "
      />
      <path
        class="svg-shoes-lace-fill"
        d="M73.9,209.5l5.4,1.3l-1.6,0.9l-7.1-1.7C70.6,210.1,72,209.5,73.9,209.5z"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="68.6,215.3 72.2,210.2 70.6,210.1 66.4,216  "
      />
      <path
        class="svg-shoes-lace-fill"
        d="M63.3,213.2l4.5,1.1l-1.4,1.6l-6-2.2C60.5,213.8,61,213.1,63.3,213.2z"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="58.2,218.3 61.8,214.2 60.5,213.8 56.1,218.7  "
      />
    </g>
  </svg>
</template>

