<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="480 438 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M577.9,495.2c0,0,1.2-0.1,1.1,0.6c0,0.7,0.3,0.9,0.5,1.1c0.3,0.2,1.2,1,1.2,2.3   c0,1.3,0,2.4,0.2,2.8s0.2,3.4-3.5,4.6s-4.9,1.7-14.9,1.8c-10,0.1-25.7,0.7-27.3,0.7c-1.6,0-20,0.8-24.8,0.4c-4.8-0.4-6.3-1-8-1.4   c-1.8-0.3-12.4-3.1-14-3.8s-6.4-3-7.2-5.9c-0.8-2.8,0.7-5.4,1.2-5.6s6.3,0.9,6.3,0.9l72.9-0.9L577.9,495.2z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M576.1,476.5c0,0-2-4-3-4.9c-1-0.8-2.5-1.5-4.7-0.3c-2.2,1.2-3.4,2.5-4.1,3.4   c-0.8,0.9-2.7,2.7-6,2.7c-3.3,0.1-5.9-0.2-7.3-1c-0.7-0.3-1.4-0.3-1-1c0.4-0.7,1.9-2.2,2-4c0-1.3-1.2-2.8-2.5-4.1s-2.6-2.5-3.2-3   s-1.3-0.9-2.7-0.2c-1.4,0.7-5.4,4-6.2,4.4c-0.8,0.5-1.6,1.1-2.4,1.3c-0.8,0.1-1.9,0.5-2.3,1.5c-0.4,1-1,1-1.4,1.1   c-0.3,0.1-1.3,0.4-1.8,1.1s-0.8,2-1.5,2.4c-0.7,0.4-2,0.8-2.6,1.3c-0.6,0.5-0.6,1.2-1.2,1.4c-0.6,0.1-2,0.5-2.3,1   c-0.3,0.5-0.2,0.7-1.3,0.9c-1.1,0.2-1.5,0.4-2,0.8c-0.5,0.3-1.3,0.1-2.1,0.8c-0.7,0.7-1,1.2-2.1,1.5c-1.1,0.2-2.4,1.1-2.8,1.6   c-0.4,0.4-0.8,0.4-1.6,0.6c-0.7,0.2-0.5,0.5-0.9,0.6s-1.2,0.2-1.7,0.5c-0.5,0.3-1.9,1-4.3,1.3c-2.5,0.3-7.1,1.4-9.9,1.6   c-2.8,0.2-7.3-0.1-8.9,1.6c-1.6,1.8-1.6,1.8-1.6,1.8s2,0.5,2.5,1.8c0.6,1.3,0.8,1.5,1,3.3c2.7,1,12.9,4.7,24.3,4   c11.4-0.7,23.8-2.6,29.2-4.2c2.9-0.6,4.1-0.3,6.1,0.7c2,1.1,5.5,0.4,7.6,0c2.1-0.4,9-2.7,14.9-2.9c5.9-0.1,8.5-0.1,9.5-0.7   c0.1-0.9,0.4-3.6-0.1-4.4c-0.4-1.5-0.8-5-0.9-6.7c-0.1-1.7,0-3-0.3-3.8s-0.3-2.3-0.3-2.6C576.3,477.4,576,476.7,576.1,476.5z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M541.2,474.1c0,0-2.3-3.5-6.9-4.1c-0.8,0.4-1.5,0.5-1.5,1.3c1.5,0.3,5.3,1.7,6.7,3.3   C540.4,475.1,541.8,474.8,541.2,474.1z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M536.1,475.8c0,0-2.4-1.4-3.3-2s-1.6-1.4-1.6-1.4s-1.1,0.3-1.4,0.7c0.6,0.8,2.9,2.8,3.9,3.5   c0.9,0.6,1.3,0.7,1.7,0.4S536.8,476.1,536.1,475.8z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M528.5,479.9c0,0-2.2-1.7-4.2-1.4c-1.2,0.3-2.1,0.4-2.5,1.1c1.9,0.1,5.4,0.6,5.9,1.7   C528.4,481.4,529.1,480.8,528.5,479.9z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M522.4,483c0,0-3.4-1.7-4.6-1.6c-1,0.3-1.5,0.5-2,1.2c2.2,0.1,4.9,1,5.5,1.8   C522,484.5,523.2,483.8,522.4,483z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M516,486.3c0,0-0.8-2-2.8-2c-0.7,0-1.1,0.1-1.6,0.7c1.2,0.4,2.9,1.2,3.3,2.4   C515.4,487.6,516.3,487.7,516,486.3z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M511,485.9c0,0-0.3,1.1,0.9,2.5c-0.4,1.2-0.8,2.1-1.9,1.1c-0.6-1-1-3.2-0.8-3.4   c0.2-0.2,0.8-0.8,1.5-0.7S511,485.9,511,485.9z"
      />
    </g>
  </svg>
</template>

