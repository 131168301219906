<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="180 569 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-shadow-fill" d="M227.5,603.5c0,0,2-0.6,2.4,0.8c0.4,1.5,0.7,2.4,0,4.1c-0.7,1.7-1.6,3.5-0.8,4.5   c0.8,1,2,1.1,3.6,0.9c1.6-0.1,18.4-2.8,18.4-2.8l-9.5,7.1l-22.3-2.2L227.5,603.5z"/>
      <path class="svg-shoes-sole-fill" d="M182.1,623.3c0,0-0.9-0.1-1.1,1.5c-0.1,1.6,0,2.9,1.1,4.4s4.9,3.9,7.1,4.5   c2.2,0.6,4.2,0.8,4.7,0.7c0.6,0.9,1.6,1.7,2.6,1.9c1,0.2,2.3,0.6,2.9,0.6c0.6,0,1.2-0.3,1.8-0.9c0.2-0.2,0.5,0,0.7,0.5   c0.2,0.5,0.6,1.2,1.5,1.4s1.6,0,1.6,0s0.3,0.6,1.5,0.6c1.2,0,1.7,0,1.9-0.3c0.2-0.3,0.2-0.9,0.5-0.9c0.3-0.1,0.6-0.3,0.9,0.4   c0.4,0.7,0.8,1.4,1.6,1.4c0.8,0.1,1-0.2,1.3-0.3c0.2-0.1,0.6,0.2,0.7,0.6c1,0,2.8,0.1,3.2-0.7c0.4-0.8,0.4-1.4,1-1.4   s0.6,0.4,0.7,0.7c0.1,0.3,0.3,1.2,1.3,1.2s4.3-0.2,4.3-0.2s0.8,0,1.2-0.8s0.9-1.4,1.2-1.4s0.3,0.8,0.4,1.2c0.1,0.3,0.1,1,1.6,1   c1.6,0,3.8-0.2,5-0.2c1.1,0,2.9-0.7,2.9-1.8c0-0.9-0.2-1.2-0.2-1.2s0.3-0.5,0.7,0c0.3,0.6,1,2.8,3.1,2.6c2.2-0.1,4.5,0,4.5,0   s1.6,0.1,2-1.1c0.5-1,1.3-2.1,1.5-1c0.2,1.1,0.5,2.4,2.5,2.4c2,0,6.7,0,7,0s1.8-0.3,1.7-2.3c0.8,0,1.2-0.1,1.6,0.8   c0.4,0.9,1.6,1.4,3.9,1.1c2.3-0.3,7.6,0.3,11.8-1.7c4.1-1.9,5-3.1,4.9-4.4c0-1.3-0.5-4.2-1-4.4c-0.5-0.1-0.6-0.7-0.8-1.5   s-1.1-3-2.4-3.3s-61.7,2.1-61.7,2.1L182.1,623.3z"/>
      <path class="svg-shoes-color-fill" d="M182.1,623.3c0,0,0.4-3.7,3.4-4.1s12.8-1.4,16.8-2.3c4-0.9,10.8-2.6,16.3-6.9   c5.4-4.3,6.6-5.5,7.6-6c1-0.5,2.7-1,1.8,0.7c-1,1.6-3,7-1.1,9.2c1.5,1.3,4.4,1,5.9,0.7c1.5-0.3,10.4-1.8,13.2-2.5   c2.8-0.7,3.5-0.7,4.9-1c1.4-0.3,2.2-1.1,3.4-1.2s3.3-0.1,5.5-0.8c2.2-0.7,5.4-1.8,6.6-2.2c1.1-0.4,1.8-0.8,2.4-2.8s2.1-2.9,2.9-3   c0.7-0.1,1.4-0.2,1.5,0.7c0.1,0.5,0.4,0.8,0.6,0.9c0.2,0.2,0.4,3.2,0.4,3.8c-0.2,0.6,0,1.3,0.2,1.7s1.8,4.3,2,5.9   c0.3,1.6,0.5,5,0.6,5.8c0,0.8,0,3,0,3.2s-3.1,1.8-5.2,2.1c-2.1,0.2-9.7,0.3-13.6,0.4s-14.6,1.6-17.7,2.3   c-3.1,0.7-10.8,2.2-15.5,2.4c-4.7,0.3-13.5,0.6-20.8,0c-7.3-0.6-13.6-1.7-18.3-3.4C182.2,625,182.2,624.3,182.1,623.3z"/>
    </g>
  </svg>
</template>
