<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="32 302 100.25 87"
    style="enable-background:new 0 0 537.5 273;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-sole-fill" d="M128.7,360.8c0,0,0.5,0.2,0.2,0.9c-0.2,0.8-0.3,1.7,0.2,2.1c0.5,0.4,1.5,1,1.8,1.2   c0.3,0.2,0.3,0.6,0.3,0.9c0,0.3-0.6,2.4-1.2,3.3c-0.7,0.9-1.9,1.9-2.5,2.1c-0.6,0.2-2.1,1-4.7,1.1c-2.6,0.1-8,0.4-8.7,0.4   s-9.5,0.1-11.8,0c-2.4-0.1-9.4,0.3-12.3,0.3s-13.4,0.1-15.3,0c-1.9-0.1-8.2,0.1-9.8,0.2c-1.6,0-12.1-0.9-15.7-1.4   c-3.7-0.6-6.7-1.2-9.8-2.1c-3-0.9-5.9-2.5-6.7-3.3s-1.8-2.2-1.2-4.4s0.7-1.9,1.1-2s4.4,0.5,4.4,0.5l72.1-11l18.8,7.1L128.7,360.8z"/>
      <path class="svg-shoes-color-fill" d="M127.1,343.4c0,0,0.5-0.2,0.3-0.9c-0.1-0.6-1-1.1-1.7-3.1c-0.7-2-1.9-3-2.6-3.2s-1,0-1.3,0.1   c-0.3,0.1-0.9,0.2-1.1,0.2c-0.2,0-1.8,0.5-2.8,1.8c-0.9,1.3-1.4,2.4-2.2,4c-0.5,0.8-1.8,1.7-4.9,0.7c-3.1-1.1-5.1-2.8-8.2-6.1   c-0.2-0.3-0.5-0.3-0.2-1.2c0.2-0.7-0.3-1.9-0.5-2.5c-0.3-0.5,0-0.9-0.2-1.5c-0.1-0.6-0.3-1.4-0.8-2.1c-0.3-0.6-0.3-0.7-0.4-1   c-0.1-0.3-0.9-1.1-2.2-0.8c-1.3,0.3-1.4,0.4-1.7,0.6c-0.3,0.2-0.6,0.2-0.7,0.1c-0.1,0-0.5,0.1-0.6,0.2c-0.1,0.2-0.4,0.2-0.5,0.2   s-2.6,1.1-4.6,2.3c-0.8-0.1-3.1-0.4-3.5,1.7c-0.7,0.3-1.4,0.6-1.2,1.8c-0.1,0.4-0.4,0.7-0.9,0.7c-0.4,0.1-1.6,0-2.5,0.3   s-0.9,0.9-1.3,1.2c-0.3,0.3-1.2,0.9-1.9,1.2c-0.7,0.2-1.6,0.8-2.4,1.7c-0.4,0-2.4-0.4-3.2,1.1c-0.3,0.6-0.3,0.9-0.5,1.1   c-0.2,0.2-0.8,0.5-1.4,0.8c-0.5,0.3-0.6,0.5-0.9,0.6c-0.2,0.1-0.1,0.2-1,0c-0.8-0.2-2.1-0.1-2.4,0.5c-0.3,0.6-0.6,0.8-0.6,1.5   c-0.5,0.1-1.7,0.7-2.2,1s-0.7,0.5-1.4,0.6c-0.7,0-1.7,0.8-1.9,1c-0.3,0.2-0.7,0.6-0.9,0.7c-0.2,0.1-0.3,0.1-0.7,0.1   c-0.4,0-1.5-0.2-2.4,0.4c-0.4,0.3-0.6,0.6-0.7,1.1c-0.5-0.1-1.4,0-2.1,0.4c-0.7,0.4-1.2,0.5-2.1,0.8c-1,0.3-4.5,1.3-7.1,1.8   c-2.6,0.5-5.9,0.9-6.9,0.9c-0.9,0-2.8,0-4.5,1.8c-0.7,1-1.3,2.7-1.4,3.8c0,0.6,0.1,0.7,0.1,0.7s12,3.9,19.1,4.9   c5.8,0.5,12,0.9,15.4,0.5c-0.3-1.2-0.9-1.9-0.9-1.9s-0.7-0.9,0.6-1.1c1.3-0.2,1.8-0.5,2.6-0.6c0.8-0.1,1.1,0.2,1.5,0.4   c0.5,0.2,1.7,1.1,2.5,1.2c0.7,0.1,1.5,0.3,1.3-0.6c-0.3-0.9-0.5-1.1-0.5-1.1s-0.6-1,0.3-1.2c1-0.2,2.6-0.6,2.6-0.6s1-0.3,2.1,0.4   c1.1,0.7,3.7,2,4,2.3s1.5,0.5,1.2-0.4s-1-2.3-1-2.3s-0.9-1.2,0.4-1.5c1.3-0.2,2.5-0.5,2.5-0.5s0.6-0.3,1.4,0.3   c0.8,0.6,4.3,2.7,4.8,3.4c0.5,0.7,0.9,0.7,1.3,0.6c0.4-0.1,0.5-0.5,0-1.3c-0.5-0.8-1.7-2.6-1.7-2.6s-1-1.2,0.4-1.5s2.8-0.5,2.8-0.5   s0.4-0.4,1.8,0.4c1.4,0.8,7,4.3,7,4.3s0.7,0.6,1.2,0.1s0.5-0.9-0.4-1.7s-2.6-2.6-2.8-2.8c-0.2-0.3-0.9-1.4,0.6-1.6   c1.4-0.2,3-0.8,3.9-0.8c0.9-0.1,1.9-0.1,3.7,0.4c1.8,0.5,6.5,2.5,9.2,3.4s6,1.9,7.8,2c1.7,0.1,1.6,0,1.6,0s1.2-3,1.2-4.4   c0-1.3-0.3-5.7-0.6-7S128.4,345.3,127.1,343.4z"/>
      <path class="svg-shoes-lace-fill" d="M95,335c0,0-1.7-3.6-6.1-3.8c-0.7,0.1-1.4,0.4-1.4,0.4s-0.8,0.4-0.9,1.3   c-0.1,0.9,0.1,3.4,0.1,3.4h1.5l0.2-4c0,0,3.1,1.4,4.3,2.8C93.6,335.6,95.2,336.4,95,335z"/>
      <polygon class="svg-shoes-lace-fill" points="82.6,336.5 81.8,339.6 80,338.8 80.9,336.9  "/>
      <path class="svg-shoes-lace-fill" d="M85.1,336.4l-2.5,0.2l-1.7,0.5c0,0,0.6-0.9,1.1-1.1s1.2-0.5,2.3-0.4   C85.4,335.6,85.2,335.8,85.1,336.4z"/>
      <path class="svg-shoes-lace-fill" d="M74.6,343.7l-1.6-1.2V342c0,0,0.1-1.3,0.8-1.6c0.7-0.4,0.7-0.4,0.7-0.4S74.8,343.1,74.6,343.7   z"/>
      <path class="svg-shoes-lace-fill" d="M76,342.4l-1.4-0.7l0-1.7l2.4,0.8l0.8,1.1C77.8,341.9,77.4,342.4,76,342.4z"/>
      <path class="svg-shoes-lace-fill" d="M68.2,346.7l-1.5-1.2c0,0,0.1-1.8,1.4-2c0.7-0.1,1-0.1,1-0.1S68.1,343.9,68.2,346.7z"/>
      <path class="svg-shoes-lace-fill" d="M69.8,346.4l-1.6-1.4l0.4-1.5l0.5,0c0,0,1,0,1.1,0.4c0.1,0.4,0.9,2.2,0.9,2.2L69.8,346.4z"/>
      <path class="svg-shoes-lace-fill" d="M63.5,347.1c0,0-1.4,0.1-1.9,0.8c0,0.8,0,0.8,0,0.8s0.9,0.9,3,0.4   C64,348.1,63.5,347.1,63.5,347.1z"/>
      <path class="svg-shoes-lace-fill" d="M59.4,348.8c0.1,0,0.2,0.2,0,0.2c-0.5,0.2-1.2,0.7-1.1,1.1c0.1,0.5,0.3,0.7,0.4,0.8   c0,0,0,0.1,0,0.1c0,0.3,0,1.3-0.9,0.9c-1-0.4-1.3-0.4-1.6-1.4c0,0,0-0.1,0-0.1C56.7,350,57,348.6,59.4,348.8z"/>
    </g>
  </svg>
</template>
