<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="174 10 106.25 87"
    style="enable-background:new 0 0 537.5 273;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M183,83.8c0,0-1,0.1-1.2,1c-0.1,0.9,0,4,0,4l6.9,2.5l2-0.2l0.2,1c0,0,1.8,1,2.9,0.7   s2.1-0.3,2.1-0.3l0.5,1.1l3.8,0.7l0.9-0.3v-0.6l1.6-0.3l0.6,1.7h3.7l0.8-0.4v-0.7l0.8,0l0.7-2h0.7l0.5,3.4h4.2l0.4-3.3l2-0.1   l0.2,0.7l0.9-0.2l0.6,2.9l4.5-0.8l0.3-2.8h1.1l0.9,1.5l1,0.4l3.8-1.1l0.4-2.8h1.2l0.5,0.9l1,0.3l2.4-0.9l0.6-1.7   c0,0,8.2-2.1,10.6-0.3c-0.2,1.1-0.2,1.1-0.2,1.1l0.2,1l1,0.6l1.3,0.1l-0.2,1l0.7,1.3l5.6,0.2l1-2.4l1.6,0v1.5l1,0.7l3.1,0l1-2.1   l0.7,0l0.2,0.9l1.2-0.1v0.7l0.9,0.6l6.7-0.3l0.8-0.5h1.6c0,0,2.6-0.7,3.7-2c0-1.6-0.1-3.1-0.1-3.1s-0.2-0.1-0.2-1   c0-0.9,0.1-2-0.3-2.1c-0.4-0.1-0.6-1.2-0.3-1.5s0.7-0.2,0.7-1.4s0-1.7-2-1.7c0-0.7-0.5-0.4,0.3-1.8s1.5-2.9,0.7-4   c-0.9-1.1-10.8,0-10.8,0L201.7,80L183,83.8z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M183,83.8c0,0-2.4-7.5,1.2-11c0,0,2.4-3.1,8.6-3c3.9-0.2,12.7-2.5,19.5-6.5s10.8-6.5,13.4-8   c2.7-1.5,4-3.8,4.6-5.1c0.6-1.3,2.4-4,2.9-5.5c0.5-1.5,0.3-2,0-3.1c-0.3-1.1,0.1-3.7,0.3-4.8c0.2-1.2,0.6-2.8,0.2-4.9   s-0.3-4.1,1-7.5s3.7-10,4.4-11.6s0.8-2,0.9-2.1c0.1-0.2,0.4-0.4,1.3,0c0.7-1.3,0.8-1.8,1-2.1c0.1-0.3,0.7-1,1.4,0s2,2.7,2.6,3.1   c0.6,0.4,0.8,0.4,2.4,0.7c1.6,0.2,2.5,0.4,3.6,0.7c1.1,0.3,2.5,0.4,3.9,0.1c1.4-0.3,3.8,0,4.9,0.3c1.2,0.3,1.2,0.2,1.5,0.3   c0.3,0.1,0.1,0.2,0.4,0.4c0.3,0.2,0.5,0.2,1,0.2s1.4,0.1,1.7,0.1c0.3,0,0.4,0.3,0.4,0.7s-0.3,1.3-0.5,1.7s-0.3,2.4-0.2,2.8   s-0.2,1.7,0.7,2.4s0.7,0.8,1.1,1c0.4,0.2,0.7,0.1,1.1,0.9c0.4,0.9,0.7,1.8,1,3.1c0.3,1.3,0.4,2.8,0.8,4.2c0.3,1.4,1.1,5.1,0.9,7.1   c-0.2,2-1,3-0.7,3.7c0.2,0.6,1.1,1.9,1.2,3c0.1,1.2-0.2,2.1,0,2.6c0.2,0.6,1.2,2.1,1.2,3s-0.1,0.6,0.5,2.3c0.6,1.6,1.4,3.9,2.1,5.3   c0.7,1.4,2.9,5.5,2.9,8.8c0,3.4-0.3,5.5-0.3,6.3c0,0.8,0.1,1.6-0.6,2.5c-0.6,0.9-2.4,3.1-5.3,3.8c-2.8,0.8-8.1,1.4-18.7,2.1   c-10.6,0.7-21.6,4.6-30,5.3c-8.4,0.7-17.6,1.4-24.9,0C191.1,85.8,183,83.8,183,83.8z"
      />
    </g>
  </svg>
</template>

