<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewbox="0 0 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(-331,-442)">
      <path class="svg-shoes-sole-fill" d="M425.9,492.9c0,0,0.5,0,0.6,0.4c0.1,0.3,0.5,1.2,0.8,1.5c0.3,0.3,1.3,1.5,1.8,2.5   c0.5,0.9,1.2,2.1,1.3,2.5c0.1,0.4,0.7,1.7,0.6,3.5c-0.1,1.8-1.1,2.6-1.3,2.8c-0.2,0.2-3.8,2.9-7.8,3.7c-4,0.8-9.6,1-11.3,0.9   c-1.7,0-9.9-0.1-11.3-0.1c-1.4,0-2.9-0.1-3.5-0.2c-0.6-0.1-4.9-0.9-7.3-1s-7.4-0.3-9.2,0.2c-1.9,0.5-3.4,1.2-7.2,1.1   c-3.8-0.1-14.6,0.1-17.3-0.2c-2.6-0.3-10-1-16.4-3.9s-7-5.1-7.2-5.6c-0.2-0.6-0.3-1.9-0.2-3.1c0.1-1.2,0.8-3.1,1-3.4   c0.2-0.3,0.5-0.3,1.2-0.5c0.7-0.1,2.9,0.7,2.9,0.7l48.7-6.1L425.9,492.9z"/>
      <path class="svg-shoes-color-fill" d="M422,473.3c0,0-0.6-1.4-1.7-0.6c-1.1,0.8-2.6,2.7-3.6,3.9s-2.7,3.2-3.4,3.7   c-0.8,0.6-2.4,1.5-5.9,0.4c-3.5-1.1-8.3-3.8-10.8-5.7c-1.2-0.9-2.2-1.3-2.4-1.9c0.6-1.7,1.5-5.6,0.7-7.5c-0.8-1.9-1.6-3-2.6-2.7   c-0.4-0.2-1.2-0.3-1.8,0.2c-1,0.5-1.9,1.4-2.5,2.1c-0.7,0.6-4.6,5.2-4.8,5.4c-0.5-0.1-1.3-0.1-1.8,0.4c-0.5,0.4-0.3,0.7-0.3,1   c0,0.4-0.1,0.9-0.1,1.1s-0.4,0.5-0.6,0.7c-0.3,0.1-0.9,0.5-1,0.8c-0.1,0.2-0.7,0.4-1.1,0.4c-0.5,0.1-1.4,0.4-2.1,2   c-0.3,0.7-0.7,1.3-1.1,1.5c-0.4,0.1-0.6,0.3-1.7,0.1c-0.9-0.1-2.6,0.2-3.1,1.2c-0.5,1-0.9,1.9-1.8,1.3c-0.6-0.2-2.1,0-2.6,1   c-0.5,1.1-1.3,1.9-1.3,1.9s-1.4-0.4-1.9,0.1c-0.5,0.5-1,0.7-1.4,1.5c-0.1,0.1-0.3,0.2-0.4,0c-0.2-0.1-0.9-0.1-1.5,0.3   c-0.5,0.4-0.4,0.5-0.6,0.9c0,0.3-0.3,0.5-0.6,0.5s-0.4,0.1-0.5,0.2c-0.1,0-0.7,0.5-1.2,0.6c-0.5,0.1-6.9,1.6-8,1.8   c-1.1,0.3-6.1,1.5-7.1,1.7c-1,0.2-3.6,0.6-5,0.8c-1.4,0.3-2.7,1.2-2.9,1.6c-0.1,0.4,0,2.8,0,2.9s0.1,0.5,0.4,0.6   c0.4,0.1,2,1,2.9,1.2c0.9,0.2,5.2,1.2,8.4,1c2.9-0.2,6.4-0.9,8.2-1.6c0.7,0.4,2.5,1.9,3.1,2.2c0.6,0.3,2.3,1.4,4.7,0.6   c1.8-0.6,5.1-3.4,6.7-7.2c1.2,0,3.2,0.6,6.8,2c3.7,1.4,7,2.8,8.4,3.1c1.4,0.3,4.9,1.1,8.5,0.8c3.7-0.3,19.3-2.4,25.3-4.1   c6.1-1.7,8.5-2.3,9.1-2.7c-0.3-0.4-0.3-1.5-0.4-2.4c-0.1-1-0.7-7-1.1-9.6s-0.9-5.5-1-5.9c-0.1-0.3-0.3-1.2-0.8-1.5   S422,473.3,422,473.3z"/>
      <path class="svg-shoes-lace-fill" d="M382.5,470.5c0,0-0.4,0.5,0.4,1.5s3.5,4,3.9,5.6c-1.3,0.9-1.8,1.2-1.8,1.2s-2-4.1-2.7-4.5   c-0.6-0.4-1.5-1.7-1.5-1.7s0.1-0.8,0-1.2C381.1,471.1,381.7,470.5,382.5,470.5z"/>
      <path class="svg-shoes-lace-fill" d="M382.7,476.7c0,0-1.5-1.7-2.8-1.7c-1.3,0-1.3,0-1.3,0l-0.6,0.1c0,0-0.6,0.8-0.6,1.6   c1.2-0.3,2.2,0,4.3,1.1C382.2,477.2,382.7,476.7,382.7,476.7z"/>
      <path class="svg-shoes-lace-fill" d="M375.5,483.4c0,0,0.8-1.2,1-2.7c0.1-1.5,1.2-5.4,1.5-5.6c-0.6,0.2-1.6,0.6-2.1,2   c-0.7,1.2-0.7,1.5-1.1,2c-0.4,0.5-1,2.5-0.8,3.8C374.9,483.2,375.2,483.4,375.5,483.4z"/>
      <path class="svg-shoes-lace-fill" d="M373.1,478.6c0,0-1.2,0.5-1.8,1.8c-0.6,1.3-0.2,2.6-0.2,3.1s-0.2,1.3-0.5,1.6s-0.7,0.2-1,0.1   c-0.3,0-0.8,0.1-0.7-0.8c0-0.9-0.2-2.1-0.1-2.4c0.1-0.3,0.3-0.9,0.4-1s0.9-1.6,1.3-1.9C371.1,478.9,371.7,478.6,373.1,478.6z"/>
      <path class="svg-shoes-lace-fill" d="M374,480.4c0,0-1.1-0.8-2.3-0.7c-1.2,0.2-1,0.5-1,0.5l0,0.9l0.4,0.4L374,480.4z"/>
      <path class="svg-shoes-lace-fill" d="M367.9,481c0,0-1.5,0.7-1.9,2.6c-0.1,1.1,0.3,1.6,0.3,2c0,0.4-0.3,1.4-0.3,1.4l-1.6,0.4   c0,0-0.9-0.7-0.4-2.2c0.4-1.5,0.5-1.4,0.8-1.8c0.3-0.4,1-1.6,1.1-1.7S366.6,480.9,367.9,481z"/>
      <path class="svg-shoes-lace-fill" d="M368.7,482.5l-2.7,1.4l0.6-2.4c0,0,1.8,0.1,2.3,0.4c0.5,0.2,0.3,0.4,0.3,0.4L368.7,482.5z"/>
      <path class="svg-shoes-lace-fill" d="M360.4,485.5c0,0-0.6,0.5-0.1,1.4c0.5,0.9,1.2,1.4,1.2,1.4s-0.3,1.3-0.7,1.4s-1.6-0.1-1.9-0.7   s-0.6-2-0.2-2.7C359.2,485.8,359.6,485.4,360.4,485.5z"/>
      <path class="svg-shoes-lace-fill" d="M364,483.9c0,0-1.5,0.3-1.6,1.8c-1.9,1-1.9,1-1.9,1s0.5-1.3,0.7-1.6   C361.4,485,362.1,483.6,364,483.9z"/>
    </g>
  </svg>
</template>

