<template>
<svg version="1.1"
     xmlns="http://www.w3.org/2000/svg"
     xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px" y="0px"
     viewBox="182 438 100.25 87"
     xml:space="preserve"
>
  <g xmlns="http://www.w3.org/2000/svg">
    <path class="svg-shoes-sole-fill" d="M280.8,493.1c0,0,0.9,1.9-1.6,3.7c-0.2,1.4-0.2,1.9-0.3,2.2c-0.1,0.3-0.2,0.6-0.7,0.7   c-0.4,0.1-3.4,1-4.1,1s-1.5,0-1.5,0s-0.7-0.2-0.8,0.3c-2,0.5-7.5,1.3-8.2,1.4s-0.9,0.2-1.4,0c-0.6,0-1.2,0.1-1.6-0.3   s-1.6-2.2-2.1-2.4c-3,0.4-16.4,2.1-23.3,4.2c-1.8,0.7-2.4,0.7-2.4,0.7s-1,0.7-1,2.2c-2.2,0.5-4.5,1-4.5,1s0.2,0.7-0.5,0.9   c-0.7,0.3-7.4,1.4-9.1,1.6s-5.2,0.1-6.3,0c-1-0.1-1-1.8-1-1.8s-0.2,0.2-3,0c-0.4-0.7-0.5-1.1-0.5-1.1l-5-0.6l-1.5,2.6l-4.2-0.6   l-0.3-0.9l-0.7-0.2l-0.2-1.3l-0.7-0.2v0.3l-0.9-0.2l-0.4,0.8c0,0-5.3-1.6-7.5-2.8c-0.6-0.6-0.6-0.6-0.6-0.6s-0.5-0.1-0.7-0.2   c-0.2-0.1-0.6-1.3-0.6-1.3s-2.1-0.8-2.6-2.5c0-0.6-0.4-0.8,0.6-1c1-0.2,7.3-0.8,7.3-0.8l89-7.7L280.8,493.1z"/>
    <path class="svg-shoes-color-fill" d="M275,466.9c0,0-0.6-0.7-2-0.5c-1.4,0.2-3.4,0.6-4.1,1.7c-0.7,1.1-1.3,2.3-1.4,2.6   c-0.1,0.2-0.6,1.3-0.8,1.5s-0.6,0.9-1.9,1.4c-1.3,0.5-3.2,0.9-4.4,1.1c-1.2,0.2-4,0.5-5,0.6s-3,0.3-3.4,0.3s-0.7,0.1-1.3-0.2   c-0.6-0.3-1.1-0.3-1.4-0.7c-0.3-0.3-2.5-2.8-3-3.3c-0.5-0.6-2.7-2.6-2.9-2.7c-0.1-0.1-0.4-0.4-0.4-0.4s0.5-0.4,0.7-0.7   c0.1-0.2,0.2-0.6-0.1-1.1s-2-1.8-2.1-1.9c-0.1-0.1-0.3-0.2-0.3-0.2c-0.1,0-0.3-0.1-0.5,0.1c-0.2,0.2-1.3,1.1-1.5,1.4   c-0.2,0.3-0.7,0.7-1,0.8c-0.3,0.2-2.4,1.4-2.4,1.4s0-0.6-0.2-0.8c-0.2-0.2-0.3-0.2-0.3-0.2l-2.4,1.7c0,0-0.3-0.2-0.1-0.3   c0.2-0.2,0.6-0.7,0.3-0.7s-6.7,5.2-6.8,5.3c-0.1,0.1-0.1,0.3,0.1,0.3c0.2-0.1,0.8-0.3,0.6,0c-0.2,0.3-6.2,5.2-6.4,5.3   c-0.5,0-1,0.3-1,0.3s-0.1-0.2-0.3-0.1c-0.2,0.1-5.6,3.7-5.7,3.8s0,0.3,0.2,0.2c0.1,0.1-0.1,0.4-0.1,0.4s-2.6,1.7-2.7,1.8   c-0.5-0.2-1.2-0.1-1.5,0c-0.3,0.1-6.3,2.4-7.3,2.6c-1.1,0.1-4.3,0.5-7.5,1.4s-4.7,1.2-5.7,1.3s-1.8,0.5-2.4,0.6s-1,0.2-1.4,0.2   s-0.7-0.1-1.2,0.1c-0.5,0.3-3.1,1.9-2.2,7.2c1,0.8,2.6,2.7,3.9,2.8c1.3,0.1,7.8,1.4,10.5,1.9c2.7,0.5,8.7,1.4,13.6,1.4   c5,0.1,16.5-1.2,19.9-2.4s16-4.2,19.4-4.4c3,0,25-1.7,28.2-2.5c1.4-0.8,2.3-2.5,3.5-2.5c0.5-3.2,0.6-7.8-1.8-12.1   c-0.4-0.4-1-2.7-1-2.7s-2.2-8.6-2.5-9.5c-0.3-0.9-0.6-1.5-0.6-1.5L275,466.9z"/>
    <path class="svg-shoes-lace-fill" d="M242.9,477.2c0,0,1.4-3.4-2.1-4.5c-3.5-1.1-5.8,1.9-5.5,3.6   C235,478.7,240.1,481.8,242.9,477.2z"/>
    <path class="svg-shoes-lace-fill" d="M226.5,481.5c0,0-1.8-2.6-5-1c-3.2,1.6-2.5,4.1-2.4,4.4c0.1,0.2,1.7,3,5.3,1.1   C226.5,485,227.1,482.7,226.5,481.5z"/>
    <path class="svg-shoes-lace-fill" d="M210.4,485.2l3.7,2.5c0,0-0.2,1.4-1.3,1.6c-1.1,0.2-1.8,0-2.8,0c-0.9,0-2.1-0.2-2.8-0.2   c-0.7,0-1.8-0.2-2.3-0.6s-1.9-0.9-2.2-0.8c1.2-0.4,6.9-2.5,6.9-2.5S210.2,485,210.4,485.2z"/>
    <polygon class="svg-shoes-lace-fill" points="239,472.5 233.3,468.6 233,468.8 238.6,472.5  "/>
    <polygon class="svg-shoes-lace-fill" points="235.3,476.9 228.2,471.9 228,472.1 235.4,477.1  "/>
    <polygon class="svg-shoes-lace-fill" points="222.8,480.1 220.5,478.9 220.1,479 222.5,480.2  "/>
    <polygon class="svg-shoes-lace-fill" points="219,484.4 214.7,482 214.4,482.2 219,484.8  "/>
  </g>
</svg>
</template>
