<template>
  <svg version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
       x="0px" y="0px"
       viewBox="33 569 100.25 87"
       xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-shadow-fill" d="M118,616.1c0,0,1.7-0.9,2.6-3.1c0.8-2.2,1.1-3,2-3.7c0.9-0.6,1.3,2.8,1.3,2.8l-2.4,6.4h-3.3   L118,616.1z"/>
      <path class="svg-shoes-shadow-fill" d="M90.3,605.2c0,0,2.7-2,3.3-3.1c0.6-1.1,1-1.4,1.4-1.5c0.5-0.6,0.8-2.2,2.5-1.7   c1.6,0.4,2.4,2.8,3.4,4.1s2.1,3.8,2.5,4.3c0.4,0.6,0.4,0.8,0.2,1.6s-6.8,1.5-6.8,1.5l-5.6-3.5L90.3,605.2z"/>
      <path class="svg-shoes-sole-fill" d="M35.2,621.9c0,0-2.1,1.1-2.4,3.6c-0.4,2.6-1,3.8,1.2,5.8s5.1,3.2,5.1,3.2l0.2,0.4   c0,0,3.2,1.3,4,1.4c0.4-0.1,0.7-0.4,0.7-0.4l0.4,0l0.1,0.5c0,0,5.2,1.6,8.8,2.3c3.6,0.6,7.4,0.8,7.4,0.8s1.3-0.2,2.7,0   c1.4,0.2,4.7,0.1,7.8,0.2c3.1,0.1,6.2,0,7.9-0.2c1.2-0.1,1-0.4,1.4-0.4s0.7-0.2,1.2,0.3c0.7,0.2,1,0,2.1,0c1.1,0,3.1-0.2,3.1-0.2   s0.4-0.6,1.2-0.1c0.7,0.1,6-0.2,7.6-0.5s6.9-1.2,10.6-0.8s10.5,1,14.1,1.1c3.6,0.1,6.3,0.1,7.7-0.2c1.3-0.3,2.3-0.9,3.2-1.4   s0.9-0.9,0.9-1.3s0.1-1.3,0.2-1.4c0.1-0.1,0.7-1.6,0-2.6c-2.8-0.5-38.3-4.6-38.3-4.6l-42.9,2.7L35.2,621.9z"/>
      <path class="svg-shoes-color-fill" d="M35.2,621.9c0,0,1.5-2.1,5.1-1.5c2-0.7,9-1.6,12-1.7c3-0.1,5.2-0.3,6.2-0.5c1-0.2,2.7,0,2.7,0   s2.5-1.7,5.2-1.1c0.8-0.4,1.8-0.4,1.8-0.4s0.9-1,2.1-0.8c0.7-0.4,2.1-1.3,3.8-1.5c0.4-0.9,1.1-1.2,2.6-1.1c1.4-2.1,1.8-3.6,8.3-5.5   c2.5-1,3.4-1.7,4.5-2.3s3.7-2.6,7.6-0.6c2.7,1.5,4.8,2.9,6.5,4c1.6,1.1,6.3,4.5,9.3,5.8s3.8,1.8,6.2,1.2c1.1-0.3,1.6-0.1,2.3-2.4   c0.2-1.5,0.1-4.1,1.9-4.7c1.1-0.4,1.6-1.2,2.6-0.9c1,0.3,2.2,0.7,2.9,2.4c0.7,1.8,1.7,8.2,2.1,9.4c0.4,1.1,1.1,5.3,1.1,7.4   c0,2.2-0.2,4.6-0.2,4.6s0.2,2.2-1.6,2.1s-3.7-0.3-4.1,0c-0.4,0.4-5.8-0.2-7.3-0.4c-1.5-0.1-15.1-1.1-20.1-0.8   c-4.9,0.3-13.2,1.9-16.4,2.4s-12.1,1.2-19,1.1c-6.9-0.1-20.5-2.4-22.6-3.3s-5.4-2.2-6.1-3.2s-0.4-4.7-0.2-5.6   C34.9,623.1,35.2,621.9,35.2,621.9z"/>
      <path class="svg-shoes-lace-fill" d="M66.4,617.2l0.7,2.9c0,0,1.1,0.2,1.8-0.4c-0.4-1.3-0.7-2.9-0.7-2.9S67,616.7,66.4,617.2z"/>
      <path class="svg-shoes-lace-fill" d="M71.4,615.4l1.3,2.5l2.8-0.9l-1.6-2.5C73.8,614.6,72.1,615,71.4,615.4z"/>
      <path class="svg-shoes-lace-fill" d="M74.9,616.2l0.5-1.7l0.5-0.3l2.2,1.7l1.5-0.7l-2.8-1.8c0,0-2.5-0.8-3,1.1   C74.3,615.3,74.9,616.2,74.9,616.2z"/>
      <path class="svg-shoes-lace-fill" d="M78.6,611c0,0,3.2,0.5,4.8,1.9c1.3-0.6,3-1.6,3.2-1.6c0.2,0-2.4-2.9-2.4-2.9   S81.3,608.9,78.6,611z"/>
    </g>
</svg>
</template>
