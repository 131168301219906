<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 111 60"
    style="enable-background:new 0 0 111 60;"
    xml:space="preserve"
  >
<g>
	<path class="st0" d="M7.3,32.2c0,0-1.2-0.9-1.7,2.6s-0.2,7.5,1.4,9s2.6,1.7,2.6,1.7l0.4,0.9l2.3,1.3l0.5-0.9l3.4,1.4v1.4l5.1,1.9 l0.3-1.2l3.7,1.2v1.2l5.7,0.8v-1.4l3.9,0.3V54h6v-1.4l3.9,0.1l0.4,1.3l5.6-0.4v-1.2l2.2,0l1.4,1c0,0,4.1-0.3,4.9-1 c0.2-1,0.3-1.9,0.3-1.9s6.8-1.9,10.1,0c0.3,1,0,1.3,0,1.3l5.6,1.1l1.7-0.7V53l3.4,0l0.5-0.3l0.3,0.2h4.9l0.3-0.9h1l0.5,1l2.2-0.3 v-0.4l1-0.1v0.6l2.9-0.4l1.4-0.2V51l1-0.2v0.7l2.2-0.5c0,0,0.5-1.7,1.2-0.9c1.2-0.2,2.1-0.6,2.1-0.6l0.3-1.5l0.7,0.5 c0,0,1.5-1.3,1.9-2.2c0.5-0.9,0.3-1.9-0.4-2.3c-0.7-0.3-1.5-1.9-1.9-2.2c-0.3-0.3,0.9-3.1,0.5-4.4s-1.4-1.5-1.1-2.4 s0.5-2.2-0.4-2.1S7.3,32.2,7.3,32.2z"/>
  <path class="st1" d="M7,31.9c0,0,1.7-5.1,4.6-5.6s2.4,0.5,5.3,0.7s9.6,0.3,11.6-0.3c2-0.7,2.4-1.7,2.9-1.4c0.5,0.3,0.9,1,2-0.5 c1.2-1.5,3.1-0.2,3.9-0.3c0.9-0.2,3.4-1,5.6-2.2c2.2-1.2,9.6-5.6,11.6-7c2-1.4,4.1-3.2,6.1-4.6s6-4.1,7.7-4.1s6,2,6,6.7 c2.6,2,4.1,3.2,5.8,3.9s5.8,1.9,7.8-0.5s5.1-6.1,7.8-4.3s5.1,6,5.3,9.4c0.2,3.4,0.7,8.7,0.5,11.1c0.9,1.2,0.5,1.2,0.5,1.2 s-1.4,3.8-4.6,4.4c-3.2,0.7-8.9,0.9-13,0.5c-4.1-0.3-7.7-0.5-13.8-0.2c-6.1,0.3-13.1,2.7-18.4,4.1S37.7,47.6,28.1,46 s-16-3.2-18.9-5.5C6.3,38.3,6.3,35.3,7,31.9z"/>
  <path class="st0" d="M57.8,12.3c0,0,3.1-0.9,4.2,0c1.1,0.9,1.6,1,2.7,2.4c-0.6,0.4-0.9,0.5-0.9,0.5s-1.8-1.9-3.6-1.8c-1.8,0.1-2.2,0.2-2.2,0.2L57.8,12.3z"/>
  <path class="st0" d="M59.8,18.3c0,0-2.4-4-2-6.1c-0.9,0.6-1,0.7-1,0.7s-0.9,2.4,1.5,6 C59.1,18.5,59.8,18.3,59.8,18.3z"/>
  <path class="st0" d="M54.5,15.5c0,0-0.6,3.8,0,6.1c-0.9,0.7-0.9,0.7-0.9,0.7s-1.4-2.5-0.5-5.8 C53.6,16.1,54.5,15.5,54.5,15.5z"/>
  <path class="st0" d="M57.2,17c0,0-1.8-1.1-2.8-1c-0.1,0.7-0.1,1.2-0.1,1.2s1.8,0,2.2,0.7C56.9,18.6,57.2,17,57.2,17z"/>
  <path class="st0" d="M50.5,18.4c0,0-0.7,2.3-0.5,5.5c-1.3,0.7-1.5,0.6-1.5,0.6s-0.3-3.9,0.5-5.5 C49.6,18.7,50.5,18.4,50.5,18.4z"/>
  <path class="st0" d="M52.9,20c0,0-0.9-1.3-2.5-1.1c-0.4,0.9-0.2,1.3-0.2,1.3s2.1,0.1,2.6,1c0.5,0.6,0.7,0.7,0.7,0.7 S53,20.6,52.9,20z"/>
  <path class="st0" d="M45.3,20.8c0,0-1,1.3-0.2,5.8c-0.9,0.8-1.2,0.9-1.2,0.9s-1.1-3.9-0.5-5.5 C44,21.4,44.4,21.1,45.3,20.8z"/>
  <path class="st0" d="M47.7,22.2c0,0-0.9-1.4-2.6-1.1c-0.4,0.6-0.4,0.8-0.4,0.8s1.3,0,1.7,1.1 C47.4,22.6,47.7,22.2,47.7,22.2z"/>
  <path class="st0" d="M40.9,23.5c0,0,0.2,1.7,2.3,3c0.4,0.2,0.4,0.2,0.4,0.2l-1.4,1.2c0,0-2.6-1.6-3.3-3.4 C39.9,24,40.9,23.5,40.9,23.5z"/>
</g>
</svg>
</template>

<style lang="scss" scoped>
.st0{fill:#4D4E4E;}
.st1{fill:#E9E9E9;}
</style>
