<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="330 30 100.25 87"
    style="enable-background:new 0 0 537.5 273;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-shadow-fill" d="M383.5,60.1c0,0,8.5-7.7,9.7-8.1c1.2-0.5,1.9-0.5,3.3,0.4c1.3,0.9,4.6,3.4,5.4,4
    c0.7,0.6,1.6,1.5,0.7,3.7c-0.8,2.2-1.5,2.4-1.5,2.4l-9.4,2.5l-8.3-4.7"/>
      <path class="svg-shoes-sole-fill" d="M330.7,81.8c0,0-0.7,0-0.9,0.7c-0.1,0.7-0.3,1.3-0.3,1.3l0.3,0.7l-0.3,0.3l0.3,1.5l0,0.7
    c0,0,0.1,0.9,0.5,1.1c0.2,1,0.5,1.8,1.5,1.6c0.4,0.4,0.2,0.7,0.4,0.9c0.2,0.2,0.9,0.4,1.1,0.4s0.7-0.1,0.8,0.2s0.2,0.6,0.6,0.6
    c0.4,0,0.7-0.3,1.1,0.2c0.3,0.5,0.3,0.6,0.7,0.7c0.4,0.1,0.9-0.3,1.2,0.2c0.3,0.5,0.2,0.6,0.7,0.7c0.6,0.1,1.1-0.4,1.3,0.1
    c0.2,0.5,0.4,0.8,0.9,0.8s0.9-0.6,1.3,0s0.6,0.7,0.9,0.6c0.3,0,1.1-0.5,1.4,0c0.3,0.5,0.6,0.7,0.9,0.5c0.3-0.1,0.8-0.4,1,0
    c0.3,0.5,0.6,0.3,1,0.2c0.4,0,0.5,0,1.4,0.5c0.9,0.5,4,0.7,5.9,0.7c1.9,0,10.9,0,14.3-0.2s9.8-0.8,15.9-0.5
    c6.1,0.3,14.3,0.7,19.2,0.7c4.9,0,17.1-0.2,19.1-0.5s1.7-0.3,1.7-0.3s-0.2-0.6,0.4-0.4s0.6,0,0.8,0c0.2-0.1,0.8-0.1,0.9-0.4
    c0.2-0.3,0.1-0.3,0.6-0.4c0.5-0.1,1-0.4,1.1-0.8c0-0.4-0.3-1.3,0.3-1.3c0.5,0,0.8-0.7,0.8-1.1c0-0.4,0-1,0.1-1.2
    c0.2-0.2,0.2-1,0-1.3c-0.2-0.2-0.3-1.1-0.3-1.4s-0.3-1.8-0.6-2.4s-0.5-1.7-0.5-2.6c-0.1-0.9,0.2-1.2-1-1.4c-1.2-0.3-39.8,1-39.8,1
    l-34,0.7L330.7,81.8z"/>
      <path class="svg-shoes-color-fill" d="M330.7,81.8c0,0,0.9-2.6,4.6-2.9s12.7-1.3,18-2.9c5.3-1.6,8.8-3.2,13.1-5.7s11-6.1,12.5-7.1
    c1.5-1,3-3,6.8-3.7c3.8-0.7,6.3-1.1,7.5-1.1c1.1,0,3.5,0.6,5.2,1.8c1.7,1.2,5.9,5.2,8,6.4c2.1,1.1,4.5,2.5,6.6,1.6s2.4-2.2,2.9-3.9
    c0.5-1.7,2.2-4.3,3-4.6c0.9-0.3,2-0.4,1.9,0.8c0.8,0.7,2.3,3.2,3.6,8.8s2.6,10.3,2.6,12.5c-2,1.2-3.7,1.8-8.7,2.5
    c-3.8,0.7-10.3,1.7-17.9,2c-7.6,0.3-21.1-0.1-21.1-0.1s-1.5,1.8-5.2,2.2c-3.7,0.4-11.5,1.7-20.1,1.5c-8.6-0.1-18.9-2.7-23-4.3
    c-0.1-1.2-0.3-2.7-0.7-3C330.7,81.8,330.7,81.8,330.7,81.8z"/>
      <polygon class="svg-shoes-lace-fill" points="362.3,74.5 370.1,84.1 371.7,82.3 362.6,72.4 	"/>
      <polygon class="svg-shoes-lace-fill" points="375.1,78.7 366.7,70.1 368.4,69.2 376.7,77.3 	"/>
      <path class="svg-shoes-lace-fill" d="M378.3,76.1c0,0-5.3-3.5-10.8-3.5c-2.3-0.2-4.1-1.4-4.2-2c-0.7,0.3-1.1,0.7-0.9,1.7
    c0.5,0.9,0.9,1.3,3.5,1.6c2.5,0.3,8.6,1.4,11.1,3.3C377.6,76.6,378.3,76.1,378.3,76.1z"/>
      <path class="svg-shoes-lace-fill" d="M381.6,73.2c0,0-7.3-2.9-9.4-2.9s-4.9-0.6-5.8-0.1s-2.1,1.2-2.1,1.2s-1.1-1.1,0.5-2
    s3-0.9,5.9-1.1c2.9-0.2,10.5,2.6,12.4,3.6C381.9,73,381.6,73.2,381.6,73.2z"/>
      <path class="svg-shoes-lace-fill" d="M380,74.5c0,0-3.5-6-3.7-6.5c-0.2-0.6-0.3-1.9,0.2-2.2c0.5-0.4,0.9-0.7,0.9-0.7l4.4,8.3
    L380,74.5z"/>
      <path class="svg-shoes-lace-fill" d="M370,68.3c0,0,0.1-2,1.3-2.5c1.2-0.5,3.7-0.6,3.9-0.4s-1.7,1.1-1.7,1.1s-1.9,0.2-2.3,1.5
    C370.9,68.2,370,68.3,370,68.3z"/>
      <polygon class="svg-shoes-lace-fill" points="385.1,70 378.5,67.4 377.3,65.3 379.3,65.5 386.8,68.7 	"/>
      <path class="svg-shoes-lace-fill" d="M379,65.9c0,0,0.6-4,1.1-4.3c0.5-0.3,1.2,0,1.2,0s0.4-0.7-0.3-0.7c-0.7,0-2.6-0.1-2.8,1.5
    c-0.1,1.6-0.8,1.3-0.7,1.8c0.1,0.5-0.1,0.7-0.1,0.7l0.6,1.2L379,65.9z"/>
      <path class="svg-shoes-lace-fill" d="M384.9,67.9c0,0-1.1-1.9-1.1-3c0-1.2,0.7-2.2,0.7-2.2l-0.5-2.9c0,0,0.2-1.9,0.9-2.4
    c0.7-0.6,1.9-0.4,2.2-0.3s-0.9,0.8-0.9,0.8l0.2,3.9l3.8,3.2l-1.1,1.6l-3.4-2.2c0,0-0.2,0.3-0.1,0.6c0.1,0.4,1.9,3.6,1.9,3.6l-1,0.6
    L384.9,67.9z"/>
    </g>
  </svg>
</template>
