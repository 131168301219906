<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-75 198 460.5 398"
    style="enable-background:new 0 0 496 177.5;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-color-fill"
        d="M366.3,434.8c0,3.2,0,6.3-0.1,9.6c0,2.3,0,4.7-0.9,6.8c-1.6,4.5-7.9,8.6-12.7,7.6
		c-2.1,2.1-4.6,2.4-7.5,1.9c-2.9,2.3-6.3,4.3-9.8,3c-7.5,2.6-15.4,2.8-23.4,3.1c-2.4,0.1-3.4,1-5.4-0.3c-3.6-2.4-9.2,0.6-13.4,0.8
		c-8.7,0.3-17.5,0.6-26.2,0.9c-1.5,0.1-3,0.1-4.4-0.2c-2.9-0.7-4.2-1.6-7.2-1.3c-4.2,0.5-8.3,0.7-12.5,0.8c-2.2,0-4.3,0-6.4-0.3
		c-1.7-0.3-3.4-0.7-5-1c-6.9-1.3-12.6,1.1-19.5,0.6c-8.7-0.7-18.3,0.1-27,0.4c-8.2,0.3-16.8,0.7-25.1,0.9
		c-10.7,0.3-21.5-0.7-32.2-0.5c-8.3,0.1-16.7,0.1-25.1,0.5c-11.5,0.6-20.6,2.6-32.2,2.7c-6.3,0.1-13.5,1.6-19.9,1.8
		c-5.7,0.1-13.8,1.7-18.9-0.8c-3-1.4-6.2-2.5-9.6-2.8c0.8,0.9,0.1,2.6-1,3.1s-2.5,0.5-3.7,0.3c-10.1-1.2-20.1-2.5-30.3-3.6
		c-2.3-0.3-4.8-0.7-6.1-2.5c-0.9-1.1-1.1-2.7-1.9-4c-1.9-3-6.2-3.3-9.7-2.7s-7.2,1.8-10.6,0.7c-4.3-1.5-5.7-5.9-10.3-6.6
		c-8-1.3-15.3-6.3-19.5-13.3c-1.4-2.5-1.2-7.3-1.5-10.1c-0.5-3.9-0.2-7.9,1.6-11.3c1.8-3.4,5.6-6,9.5-5.6c3.3,0.4,7.7,0.5,11,1
		c4,0.7,7.8,0.9,11.8,0.9c9.6-0.1,20,2.2,29.3,4.1c4.9,1,9.1-0.7,14.2-0.3c4,0.3,7.4-0.2,11.3,0.2c3.8,0.3,7.6,0.3,11.3,0.7
		c4.5,0.4,9.2-0.3,13.7-0.3c5,0,9.2-1.7,14.2-1.9c5.2-0.2,9.5-1,14.7-1.4c27.3-2.3,53-12.3,79.8-17.8c8-1.6,15.8-2.3,23.7-4.3
		c4.9-1.3,10.1-1.2,15.1-1.9c4.3-0.6,8-1.9,12.3-2.5c5.5-0.8,9.2-1,14.7-1.6c5.2-0.5,9-1.7,14.2-2.2c4.5-0.5,8.2-0.9,12.8-1.1
		c4.7-0.2,8-0.3,12.8,0c4.3,0.3,6.7,0.3,10.9,1.4c4.8,1.2,8.8,1,13.7,2c4.3,0.9,5.7,3.2,9.5,5.2c4.1,2.2,7.1,2.5,11.3,4.5
		c3.4,1.7,7.5,3.9,10.9,5.7c4.1,2.1,7.1,3.1,11.1,5.4c3.7,2.2,6.1,1.9,9.8,4.1c6.1,3.4,12.8,5.3,19,8.8c2.6,1.5,4.8,0.7,7.6,2
		c4.2,2,6.4,7.4,5.4,11.9"
      />
      <path
        class="svg-shoes-sole-fill"
        d="M366.3,434.8c0,3.2,0,6.3-0.1,9.6c0,2.3,0,4.7-0.9,6.8c-1.6,4.5-7.9,8.6-12.7,7.6
		c-2.1,2.1-4.6,2.4-7.5,1.9c-2.9,2.3-6.3,4.3-9.8,3c-7.5,2.6-15.4,2.8-23.4,3.1c-2.4,0.1-3.4,1-5.4-0.3c-3.6-2.4-9.2,0.6-13.4,0.8
		c-8.7,0.3-17.5,0.6-26.2,0.9c-1.5,0.1-3,0.1-4.4-0.2c-2.9-0.7-4.2-1.6-7.2-1.3c-4.2,0.5-8.3,0.7-12.5,0.8c-2.2,0-4.3,0-6.4-0.3
		c-1.7-0.3-3.4-0.7-5-1c-6.9-1.3-12.6,1.1-19.5,0.6c-8.7-0.7-18.3,0.1-27,0.4c-8.2,0.3-16.8,0.7-25.1,0.9
		c-10.7,0.3-21.5-0.7-32.2-0.5c-8.3,0.1-16.7,0.1-25.1,0.5c-11.5,0.6-20.6,2.6-32.2,2.7c-6.3,0.1-13.5,1.6-19.9,1.8
		c-5.7,0.1-13.8,1.7-18.9-0.8c-3-1.4-6.2-2.5-9.6-2.8c0.8,0.9,0.1,2.6-1,3.1s-2.5,0.5-3.7,0.3c-10.1-1.2-20.1-2.5-30.3-3.6
		c-2.3-0.3-4.8-0.7-6.1-2.5c-0.9-1.1-1.1-2.7-1.9-4c-1.9-3-6.2-3.3-9.7-2.7c-3.5,0.7-7.2,1.8-10.6,0.7c-4.3-1.5-5.7-5.9-10.3-6.6
		c-8-1.3-15.3-6.3-19.5-13.3c-1.4-2.5-1.2-7.3-1.5-10.1c19.7,8.4,39.6,12.3,61,13.5c6.1,0.4,12.4,2.8,18.4,2.9
		c4.2,0.1,7.7,0.7,11.8,1.1c4.3,0.5,8.9-0.8,13.2-0.3c5.2,0.6,9-0.4,14.2-0.9c15.1-1.9,29.4-2.5,44.5-3.9
		c38.9-3.6,78-7.3,115.3-18.6c13.8-4.2,28.7-6.7,42.7-9.6c12.7-2.6,25.6-5.4,38.4-3.3c19.7,3.1,36.3,17.2,56,20
		c7.7,1,16.1,0.6,22.2,5.3"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M-13.8,428.8c-0.8-5.3-2.6-10.5-2.8-15.9c-0.6-11.1,5.8-21.2,11.8-30.4c1.6-2.4,3.2-4.8,5.5-6.5
		c3-2.3,6.9-3.1,10.7-3.6c6.1-0.8,12.5-0.7,18.6,0.2c-2.4,1.7-4.2,4.3-4.8,7.2"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M-13.9,428.4c8.7,3.6,19.6,2.4,28.5-0.6c-2-7.4-4-14.8-6-22.1c-0.9-3.4-1.8-6.9-0.9-10.3
		c0.5-2.1,1.7-4,2.9-5.7c3.6-4.8,8.6-8.6,14.2-11"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M34.6,422.2c1.1-14.7,8.4-28,15.5-40.9c1.9-3.5,3.9-7,6.6-9.9c2.7-2.9,6.2-5.3,10.2-5.7
		c11.7,10.4,24.5,21.2,33.7,34c2.8,4,5.4,8.2,7.4,12.7c2.4,5.6,3.8,11.4,5.1,17.4c11.5,0,22.8-1.8,33.8-5.5
		c-1.5-6.7-5.8-12.5-9.9-18c-4.2-5.4-7.5-12.4-11.3-18.1c-3.4-5.2-6.9-10.3-11.2-14.8c-10.3-11-25.4-17.3-40.5-17.1
		c-1.9-0.5-6.1-0.6-7.9-0.3c-13.1,1.8-29.4,4.9-37.3,15.5c-2,2.7-3.5,5.9-4.9,8.9c-6,12.8-11.6,25.7-16.9,38.8
		c8.9,2.3,18.3,3.3,27.4,2.9"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M94.1,433.1c-0.8-16.5,7.6-32,16.5-45.8c5.6-8.6,11.6-17.1,19.4-23.8
		c8.3-7.3,18.5-12.4,29.4-14.8c-0.3-2,0-2.7-0.4-4.7c-19.8,4.4-38.6,12.7-55.3,24.1c-4.7,3.3-9.4,6.8-13.2,11.1
		c-5.6,6.1-9.6,13.6-13.3,21c-5.9,11.2-11.7,22.4-14.6,34.6c-0.3-0.3-0.3-0.2,0.1-0.1c10.1,1.1,20.3-4.1,30.3-2"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M51.1,398.2c6.3,3.7,11.6,13,12,20.2c-0.4-0.6,1.8-0.7,2.5-0.9c6.7-1.4,14.4-1.1,21.1-2.6
		c-2.1-9.6-9.3-23.5-17.5-28.8c-3.9-2.6-7.8-3.9-11.9-6.1c-2.3-1.2-4.7-2.6-6.1-4.8c-4.8,3.3-9.9,7.2-12.7,12.4
		c-0.4,0.7-0.7,1.3-0.5,2.1c0.2,0.6,0.7,1,1.1,1.4C42.8,393.9,46.8,396.3,51.1,398.2"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M152.6,369.7c-1.1,10.4-1.6,22.2-2.3,32.7c8.8-2.3,16.5-4,25.3-6.3c0.5-1.8,1.7-4.4,2.2-6.2
		c0.8-3,1-6.1,1.3-9.2c0.3-2.6,0.6-5.4,0.9-8c0.7-6.6,1.3-13.3,0.9-20c10.5-1.5,20.7-0.1,30.2,4.8c3.8,2,7.3,4.4,11.2,6.3
		c1.8,0.9,9.3,6.1,11,5c0.9-0.6,0.6-5.2,1.1-6.2c3.1-6,2.2-12.7,5.3-18.6c0.3-0.6,0.6-1.1,0.6-1.8c0-1.1-1-2-2-2.6
		c-8.7-5.9-18.3-10.1-28.5-12.7c-4.5-1.1-9.6-2.2-14.2-2.9c-2.2-0.4-4.3-0.8-6.5-0.3s-4.1,1.7-5.9,2.8c-7.2,4.6-14.3,9.2-21.5,13.8
		c-2,1.2-4,2.6-5.2,4.6c-0.9,1.5-1.1,3.2-1.4,4.8C154.1,356.5,153.2,363.1,152.6,369.7"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M232.4,370.8c0.4,5.4,0.8,10.7,1.1,16.1c5.1-0.5,10.3-1,15.4-1.5c0.7-0.1,1.4-0.2,1.8-0.7
		c0.4-0.5,0.4-1,0.5-1.6c0.4-8.2,0.3-16.5,0.2-24.9c-0.1-4.5,1.9-17.7-1-21.2c-2.4-2.7-12.6-2.1-14.8,0.4c-0.8,0.9-1,2-1.2,3.1
		c-2.6,14.4-2,29.1-1.3,43.8"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M250,400.3c5.8-1.6,10.4-2.9,16.5-3.5c6-0.6,12.2,0,17.7,2.5c1.5-17.3,2.2-34.5,0.6-51.7
		c-0.1-0.7-0.2-1.5-0.8-1.9c-0.5-0.3-1-0.3-1.5-0.3c-11,0.4-21.8,0.8-32.8,1.1c-0.9,15.3-1.2,30.6,0.5,45.9
		C250.5,395.9,250.4,397.6,250,400.3"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M247.1,335c7.8,0.4,14.2,0.7,21.8-0.8c5.4-1,10.5-2.8,15.7-4.5c14.2-4.7,28.8-8.6,43.9-8.7
		c0.5,9.4,2.7,18.6,6.8,27.1c-0.1-0.2-0.2-0.9-0.2-0.8c0,0.3-0.3,0.4-0.6,0.5c-13.6,5.1-24.9,15.5-38.8,19.9
		c-6.8-4.3-13.6-9.1-20.4-13.4c-1.1-0.8-2.3-1.4-3.5-2c-1.5-0.6-3.1-0.9-4.7-1c-4.4-0.7-8.9-1.5-12.8-3.7c-3.9-2.2-7.1-6-7.6-10.4
		c-0.1-0.8,0.2-1-0.3-1.7c-0.2-0.3,0-0.5,0.1-0.4"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M304.3,372.2c8.9,6.5,19.4,10.4,29.2,15.3c16.3,8.2,30.6,19.6,44.9,30.9c1.6,1.2,3.2,2.6,4,4.3
		c0.7,1.6,0.7,3.4,0.6,5.1c-0.2,3.3-0.7,6.6-1.4,9.8c-0.3,1.1-0.7,2.5-1.7,3.1s-2.3,0.5-3.5,0.2c-4.7-1.1-9.3-3.4-13-6.5
		c-7-6.1-16.2-12-24.3-16.5c-19.9-10.9-39.7-21.7-59.6-32.6c-12.3-6.7-25.6-10.7-38.9-15.2c2.4-2.2,4.7-4.3,7.2-6.5
		c2.3-2.1,4.6-4.3,6.1-6.9c1.5-2.7,2.1-6.1,0.7-8.9c7,5.3,15,9.3,23.5,11.6c8.9,2.5,19.1,3.5,25,10.6"
      />
    </g>
  </svg>
</template>
