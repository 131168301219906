<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="330 168 100.25 87" xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-sole-fill" d="M430.9,226.8c-0.2,0.9,0.1,1.8-0.2,2.6c-0.2,0.7-0.9,1.1-1.2,1.7c-0.5,0.8-0.6,1.8-0.8,2.8   s-1,1.9-1.9,1.9c-0.7,0-1.3-0.5-1.8-1s-1.3-0.7-1.9-0.4c-0.2,0.1-0.3,0.3-0.5,0.5c-0.9,1.1-1.7,2.2-2.6,3.3   c-0.4,0.5-1.3,0.5-1.9,0.5c-0.5,0-1-0.2-1.2-0.7c-0.1-0.2-0.1-0.4-0.2-0.6c-0.5-1.1-2.7-0.4-3.2-1.5c-0.1-0.2-0.1-0.4-0.2-0.6   c-0.1-0.2-0.4-0.3-0.6-0.4c-0.8-0.3-1.6-0.5-2.4-0.6c-0.2,0-0.5-0.1-0.7,0c-0.6,0.2-0.6,1-0.7,1.5c-0.2,1-0.9,1.9-1.9,2.3   s-2.1,0.3-2.9-0.3c-0.4-0.3-0.8-0.7-1.3-0.8c-0.3-0.1-0.6,0-0.9-0.2c-0.4-0.3-0.4-0.9-0.5-1.3c-0.4-1.6-2.2-2.4-3.8-2.8   c-2.2-0.6-4.5-0.9-6.8-1c-1.2-0.1-2.3-0.1-3.4,0.3c-1,0.3-1.9,0.8-2.8,1.4c-0.3,0.2-0.6,0.4-0.8,0.6c-0.4,0.5-0.6,1.1-0.4,1.7   c-1.1,1.7-3.4,2-5.1,0.9c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.1-0.9,0.1-1.3,0c-0.2-0.1-0.4-0.3-0.5-0.5c-0.9-1.1-2.6-1.3-3.7-0.6   c-1.2,0.7-1.6,2.4-1,3.6c-1.8,1.2-4.6,0.5-5.7-1.4c-0.2-0.4-0.4-1-0.9-1.2c-0.3-0.2-0.6-0.2-0.9-0.2c-1.2,0-2.5,0-3.7,0   c-0.7,0-1.2,0.7-1.4,1.3c-0.2,0.6-0.2,1.3-0.2,2c-0.8,0-1.7-0.1-2.5-0.1c-0.4,0-0.8,0-1.1-0.3c-0.2-0.2-0.3-0.4-0.4-0.7   c-0.4-0.9-0.9-1.9-1.3-2.8c-1.1-0.6-2.1-1.1-3.4-1.1c-0.6,0-1.4,0.3-1.2,0.9c0,0.1,0.1,0.3,0.1,0.4c0,0.2-0.3,0.3-0.5,0.3   c-0.2,0-0.4-0.2-0.7-0.2c-0.7,0-0.8,0.9-1.3,1.3c-0.7,0.6-1.9,0.2-2.4-0.6c-0.6-0.8-0.7-1.7-1.2-2.6c-0.2-0.3-0.5-0.6-0.8-0.6   c-0.2,0-0.4,0.1-0.6,0.2c-0.5,0.1-0.9-0.5-1.5-0.6c-1-0.3-1.8,1.2-2.8,1.3c-0.4,0-1.1-0.2-1.5-0.2c-0.3,0-0.6-0.1-0.7-0.4   c-0.1-0.2-0.1-0.3,0-0.5c0-0.8-0.3-1.5-0.7-2.1c-0.4-0.6-0.9-1.2-1.3-1.8c-0.5-0.7-0.8-1.5-1-2.3c-0.1-0.4-0.1-0.8,0-1.1   c0.2-0.4,0.6-0.7,1.1-0.8s0.9,0,1.4,0c22.9-2.1,46.1-5.2,69-3.6c9,0.6,18.1,1.9,27.1,0.7c0,1.7,1.2,3.3,1.2,5"/>
      <path class="svg-shoes-color-fill" d="M331.9,225.1c-0.1-1.9,0.6-4,2.1-5.2c0.5-0.4,1-0.6,1.6-0.9c0.9-0.5,1.8-0.7,2.8-0.9   c5.6-0.8,11.5-2.1,15.9-5.6c0.6-0.4,1.3-0.7,2-0.7c0.2,0,0.4,0,0.5,0c0.2,0,0.3-0.1,0.5-0.2c0.8-0.2,1.7,0,2.4-0.4   c0.5-0.3,0.8-0.8,1.4-0.9c0.7-0.2,1.5,0.5,2.2,0.3c0.3-0.1,0.6-0.4,0.9-0.6c0.3-0.2,0.7-0.3,1-0.1c0.7-0.9,1.7-1.5,2.7-1.9   c1.1-0.4,2.2-0.6,3.3-0.8c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0,0c1-1.2,2.4-1.9,3.9-2.7c0.5-0.3,1.1-0.6,1.6-0.8   c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4,0.1,0.5,0.2c0.6,0.1,1.1-0.4,1.3-1c0.2-0.5,0.2-1.2,0.5-1.7c0.7-1.2,2.3-1.3,3.6-1.2   c0.2-1.4,1.5-2.4,2.9-2.4c0.3,0,0.5-0.2,0.8-0.2c0.7-0.2,1.4-1,2.2-0.9c-0.3-0.8,0-1.9,0.8-2.3c0.6-0.3,1.3-0.1,1.4-0.9   c0-0.2-0.1-0.4-0.1-0.6c-0.3-1.6,1.1-3.2,2.7-2.9c0.8-1.4,2.8-2,4.3-1.3c0.2,0.1,0.5,0.2,0.7,0.2c0.3,0,0.5-0.2,0.7-0.4   c1.2-1.1,2.5-2.1,3.7-3.2c0.6-0.5,1.6-1.1,2.2-0.5c0.3,0.3,0.4,0.8,0.4,1.2c0.1,1.3,0.1,2.6,0.2,3.8c0.1,1.3,0,2.9-1.1,3.6   c1.4,1.8,3,3.8,5.1,4.6c1.6,0.7,3.4,1.3,5.1,1c1.7-0.3,3-1.5,4.3-2.7c0.4-0.4,0.9-0.9,1.4-1.2c0.5-0.3,1.2-0.4,1.8-0.2   c0.3,0.1,0.5,0.3,0.7,0.5c0.5,0.4,1,0.9,1.4,1.4c0.4,0.6,0.7,1.4,0.7,2.2c0.3,0,0.4,0.4,0.3,0.7c-0.2,1.7,0.3,3.4,0.9,5.1   c0.9,2.7,1.8,5.4,2.8,8c1.1,3.2,0.3,7-0.6,10.3c-3.5,2.5-7.7,3.7-11.9,4.4c-3,0.5-6,0.8-9,0.2c-1.3-0.2-2.6-0.7-4-0.8   c-1.7-0.2-3.4,0.1-5.1,0.4c-2.6,0.4-5.2,0.7-7.9,1c-2.8,0.3-5.7,0.5-8.5-0.2c-0.7-0.2-1.3-0.4-2-0.3c-1.2,0.1-2.1,1-3,1.7   c-1.1,0.7-2.3,1.2-3.6,1.3c-1.3,1.1-3,0.5-4.7,0.5c-2.8,0-5.6,1.4-8.4,1.1c-1.6-0.2-3-0.8-4.6-0.8c-0.9,0-1.9,0.2-2.8,0.3   c-1.7,0.1-3.3-0.3-4.9-0.7c-1.3-0.3-2.5-0.6-3.8-1c-2.1-0.5-4.5-0.5-6.6-0.6c-1.7-0.1-3.6-0.2-4.9-1.4   C332.7,227.3,332.2,226.2,331.9,225.1"/>
      <path class="svg-shoes-lace-fill" d="M396.5,190.8c0,0-2.1-1.4-2.8-1.2c-0.7,0.2-1.4,0.7-1.4,0.7l2.9,1.7   C395.3,192,396.4,192.3,396.5,190.8z"/>
      <path class="svg-shoes-lace-fill" d="M395,195c-0.3-0.4-2.5-3-3-3.9c0-0.1-0.1-0.1-0.2,0c-0.3,1-0.6,2.1,0.1,3.3c0,0,0,0,0,0   c0.8,0.8,1.7,1.7,2,2c0,0,0.1,0,0.1,0l0.9-1.3C395.1,195.1,395.1,195,395,195z"/>
      <path class="svg-shoes-lace-fill" d="M391.3,193.6c0,0-1.3,3.3-0.4,7.5c-0.6,0.2-2.3,0-2.3,0l0.3-4.7l0.6-0.1   C389.5,196.4,388.6,194.3,391.3,193.6z"/>
      <path class="svg-shoes-lace-fill" d="M386,197.5c0,0-1.7,3.8-1.4,7.3c-0.6,0.6-1.7,0-1.7,0l0.8-4.9   C383.6,199.9,383.9,198.1,386,197.5z"/>
      <path class="svg-shoes-lace-fill" d="M379,207.7c0,0,1.2-3.9,1.8-4.8c-0.5-0.5-0.7-1.2-0.7-1.2s-3.5,3.4-2.7,6   C378.2,208.2,379,207.7,379,207.7z"/>
      <path class="svg-shoes-lace-fill" d="M375.9,205c0,0-3,1.6-3.1,5.3c-0.6,0.6-1.8,0.6-1.8,0c0-0.6,0.1-2.8,0.6-3.3   C372.2,206.6,374.3,204.5,375.9,205z"/>
      <path class="svg-shoes-lace-fill" d="M369.8,207.9c0,0-3.5,1.4-3.9,5c-0.6,0.6-1.4,0.5-1.5,0s0.1-2.1,0.8-2.9c0.7-0.8,2.3-2,4.4-2.5   C370.1,207.6,369.8,207.9,369.8,207.9z"/>
      <path class="svg-shoes-lace-fill" d="M358.8,214c0,0-1-1.7,0.4-2.5c-1.4,0.1-1.8,0-2.2,1s0.5,2.2,0.5,2.2S358.4,214.7,358.8,214z"/>
    </g>
  </svg>
</template>
