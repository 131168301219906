<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="-75 198 460.5 398"
    style="enable-background:new 0 0 537.5 273;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-color-fill"
        d="M-65.3,409.4c2.1-4.6,6.6-7.8,11.4-9.4c4.8-1.6,10-1.8,15-2.2c28.8-1.7,58.1-6.4,83.7-19.8
		c2.3-1.2,6.2-1.7,8.6-0.7c2.2-4.8,6.4-8.3,11.1-10.7c8.9-4.5,18.2-8.3,27.4-12.1c4.1-1.6,8.1-3.4,12.3-5c2.3-1,4.7-1.9,6.8-3.2
		c1.8-1,3.5-2.3,5.5-3.1c1.8-0.7,3.8-1,5.6-1.6c2.6-1,4.8-2.9,7-4.5c5.6-3.9,12.5-5.5,18.8-8.1c6.3-2.6,12.6-6.6,15.1-13
		c5-2.5,10.2-6.7,14.4-10.4c2.7-2.4,4.9-5.4,7.5-8c4.1-4,8.8-7.1,13.7-10.1c1.6-1,3.4-2.1,5.3-1.7c3.8,0.7,4.2,5.9,3.8,9.8
		c4.1-0.6,8.4,1.8,9.9,5.6c0.8,1.9,0.9,4.1,1.5,6.1c1.5,4.8,5.5,8.5,9.8,11.3c4.2,2.9,8.7,5.5,11.9,9.5c3.1,4.1,4.3,10.1,1.3,14.2
		c7.4,4,15.1,7.2,23.1,9.6c8.1,2.4,16.8,3.2,24.8,0.5c8.7-2.9,15.7-9.4,21.4-16.5c3.4-4.2,6.9-8.9,12.2-10c6.3-1.3,12.3,3,18.7,3.8
		c4.2,0.5,8.7-0.5,12.5,1.3c4.3,2.1,6.2,7,7.8,11.6c1.6,4.8,3.3,9.7,4.9,14.5c1.5,4.4,3,8.7,4.2,13.2c3.1,11.8,3.8,24.2,2.1,36.4
		c4.2,9.1,7,19.2,9.4,28.9c0.4,1.7,0.9,3.5,0.8,5.4c-0.2,4.1-2.9,7.9-6.7,9.3c4.5,2.4,6.7,7.9,6.7,13c-0.1,10.7-9.7,20.5-20.4,20.8
		c-1.1,0-2.5,0.1-3,1.1c-0.2,0.3-0.2,0.8-0.3,1.2c-0.8,3.6-5.9,3.8-9.4,3c-3.4,3.9-5.7,4.3-9.7,3.4c-1-0.3-1.3-2.1-2.3-2.1
		c-4.8-0.2-8.7,1.4-14.1,1.4c0,0.7-0.7,2.9-2.1,4.1c-1.4,1.2-5.4,0.9-7.3,0.6c-3.5-0.4-2.6-0.7-5.7-2.3c0.3,3.5-3,4.2-6.6,4
		c-4.3-0.3-6.6-0.5-7.5-4.8c-4.2,0.7-8.2-0.1-12.4,0.1c-5,3.9-11.5,5.8-15.4,0.3c-1.2,3-1.6,4.6-11.2,3.5c-3.3-0.3-4.8-5.1-3.7-8.2
		c-5.2,1.9-11,2-16.3,0.2c-1.9-0.6-3.8-1.6-5.8-1.4c-1.1,0.1-2.2,0.4-3.3,0.3c-3.3-0.3-6.1-4.3-9-2.9c-0.6,0.3-1.1,0.9-1.7,1.2
		c-3.3,1.8-7-2.9-10.6-2c-1.6,0.3-2.8,1.7-4.4,2.3c-1.6,0.5-3.8-0.9-3-2.3c-3.1,2.2-6.8,3.5-10.5,3.7c-2.5,0.2-5.2,0-7.5,0.9
		c-2.4,0.9-4.5,3.3-3.9,5.7c1.6,1.8-13.2,4.7-18.5,1c-0.7-1.1-2.5-0.6-3.2,0.5c-0.7,1-1,2.4-1.6,3.5c-1,1.5-2.9,2.1-4.8,2.5
		c-7.6,1.9-16.6,3.5-22.6-1.6c-1.6,1-3.3,1.5-5.1,1.5c0.9,1.8-0.6,4.1-2.5,4.8c-1.9,0.7-4,0.3-6-0.1c-4.1-0.8-8.1-1.6-12.3-2.4
		c-4.1,3.1-10.3,4.2-15.3,3.5c-5-0.7-9.8-2.9-14.1-5.6c-1.3-0.9-2.7-1.7-4.2-1.9c-1.6-0.2-3.4,0.6-3.7,2.2c-0.2,0.8,0,1.6-0.4,2.3
		c-0.4,0.7-1.5,0.9-2.3,0.9c-8.1,0.6-17.5,0.6-22.7-5.6c-0.7-0.9-1.5-1.9-2.6-1.8c-1.6,0.1-2.6,2.4-4.2,2c-10.4,2.1-22-3.2-29-11.2
		c-0.3-0.4-0.8-0.9-1.3-1.1s-1.2-0.3-1.8-0.4c-2.5-0.4-4.7-2.3-5.6-4.6c-0.3-0.6-0.3-1.2-0.8-1.6c-1.1-1.1-3-0.3-4.2,0.9
		c-1.2,1.1-2.4,2.5-4,2.3c-11.6-1.5-22.2-11.1-32.1-17.1c-5.6-3.5-11.3-7-15.7-11.9c-11.6-12.8-12.1-34.3-1.2-47.6"
      />
      <path
        class="svg-shoes-sole-fill"
        d="M206.2,456.5c15.6-1.5,31.1-4,46.3-7.4c16.1,0.3,31.9-3.5,47.6-7.4c21.6-5.3,43.5-10.6,63.7-20
		c4.4-2,8.9-4,13.2-6.3c3.3,9.5,3.4,10.3,5.8,20.1c0.4,1.7,0.9,3.5,0.8,5.4c-0.2,4.1-2.9,7.9-6.7,9.3c4.5,2.4,6.7,7.9,6.7,13
		c-0.1,10.7-9.7,20.5-20.4,20.8c-1.1,0-2.5,0.1-3,1.1c-0.2,0.3-0.2,0.8-0.3,1.2c-0.8,3.6-5.9,3.8-9.4,3c-3.4,3.9-5.7,4.3-9.7,3.4
		c-1-0.3-1.3-2.1-2.3-2.1c-4.8-0.2-8.7,1.4-14.1,1.4c0,0.7-0.7,2.9-2.1,4.1c-1.5,1.2-5.4,0.9-7.3,0.6c-3.5-0.4-2.6-0.7-5.7-2.3
		c0.3,3.5-3,4.2-6.6,4c-4.3-0.3-6.6-0.5-7.5-4.8c-4.2,0.7-8.2-0.1-12.4,0.1c-5,3.9-11.5,5.8-15.4,0.3c-1.2,3-1.6,4.6-11.2,3.5
		c-3.3-0.3-4.8-5.1-3.7-8.2c-5.2,1.9-11,2-16.3,0.2c-1.9-0.6-3.8-1.6-5.8-1.4c-1.1,0.1-2.2,0.4-3.3,0.3c-3.3-0.3-6.1-4.3-9-2.9
		c-0.6,0.3-1.1,0.9-1.7,1.2c-3.3,1.8-7-2.9-10.6-2c-1.6,0.3-2.8,1.7-4.4,2.3c-1.6,0.5-3.8-0.9-3-2.3c-3.1,2.2-6.8,3.5-10.5,3.7
		c-2.5,0.2-5.2,0-7.5,0.9c-2.4,0.9-4.5,3.3-3.9,5.7c1.6,1.8-13.2,4.7-18.5,1c-0.7-1.1-2.5-0.6-3.2,0.5c-0.7,1-1,2.4-1.6,3.5
		c-1,1.5-2.9,2.1-4.8,2.5c-7.6,1.9-16.6,3.5-22.6-1.6c-1.6,1-3.3,1.5-5.1,1.5c0.9,1.8-0.6,4.1-2.5,4.8c-1.9,0.7-4,0.3-6-0.1
		c-4.1-0.8-8.1-1.6-12.3-2.4c-4.1,3.1-10.3,4.2-15.3,3.5c-5-0.7-9.8-2.9-14.1-5.6c-1.3-0.9-2.7-1.7-4.2-1.9
		c-1.6-0.2-3.4,0.6-3.7,2.2c-0.2,0.8,0,1.6-0.4,2.3c-0.4,0.7-1.5,0.9-2.3,0.9c-8.1,0.6-17.5,0.6-22.7-5.6c-0.7-0.9-1.5-1.9-2.6-1.8
		c-1.6,0.1-2.6,2.4-4.2,2c-10.4,2.1-22-3.2-29-11.2c-0.3-0.4-0.8-0.9-1.3-1.1c-0.5-0.3-1.2-0.3-1.8-0.4c-2.5-0.4-4.7-2.3-5.6-4.6
		c-0.3-0.6-0.3-1.2-0.8-1.6c-1.1-1.1-3-0.3-4.2,0.9c-1.2,1.1-2.4,2.5-4,2.3c-11.6-1.5-22.2-11.1-32.1-17.1
		c-5.6-3.5-11.3-7-15.7-11.9c-11.6-12.8-12.1-34.3-1.2-47.6c1.8,1.1,2.5,3,2.7,5.2c0.2,2.1-0.3,4.2-0.8,6.3
		c-0.3,1.2-0.5,2.4-0.8,3.6c-0.6,2.8-0.1,5.8,1.1,8.3c1.2,2.6,3.2,4.7,5.5,6.5c10,8.2,23.8,9.8,35.8,14.6c2.4,1,5,1.8,7.6,2.3
		c2.9,0.5,5.8,0.7,8.6,1.6c3.7,1.2,6.9,3.6,10.6,5c3.9,1.5,8.1,1.6,12.3,1.9c5.1,0.3,10.3,0.5,15.4,0.7c6.7,0.3,12.8,0.2,19.5,0.3
		c2.8,0,5.5,0.1,8.2,0.6c2,0.4,3.9,1.2,5.9,1.7c7.6,2.2,15.7,1.8,23.6,1.4c9-0.4,18.7-0.9,27.6-1.2c1.7-0.1,3.5-0.1,5.2,0.2
		c2.3,0.3,4.4,1.3,6.6,1.9c5.3,1.4,10.8,0.7,16.2,0.1c20.4-2.6,40.6-9.8,61-12.9"
      />
      <path
        class="svg-shoes-shadow-fill"
        d="M336.5,333.7c-3.5,2.8-6.6,6.3-8.6,10.3c-2.8,5.4-4.1,11.8-8.8,15.5c-2.2,1.7-4.9,2.7-7.6,3.3
		c-10.6,2.3-21.5,0.2-32-2.3c-3.4-0.8-5.8-1.2-8.5-3.3c-1-0.9-3.5-4-4.2-4.9c6.9,2,13.3,2.3,20,0.7c3.6-0.9,7.3-2,10.4-4
		c2.6-1.6,5.4-3.7,7.5-5.9c2.2-2.2,4.3-4.4,6.5-6.9c3.7-4.2,7.5-8.1,13.2-8.6c4.6-0.3,9.2,2.4,11.2,6.5"
      />
      <polygon class="svg-shoes-lace-fill" points="54.9,375 74,396.1 78.6,392.2 58.8,370.5 	" />
      <polygon
        class="svg-shoes-lace-fill"
        points="75.7,386.5 84.5,384.9 86.7,358.9 86.7,356.7 78.7,360.1 	"
      />
      <polygon class="svg-shoes-lace-fill" points="96.1,378.8 103.4,374.3 74.6,361.9 66.7,365.5 	" />
      <polygon
        class="svg-shoes-lace-fill"
        points="106.6,380.8 106.6,385.3 112.5,385.3 119.6,342.3 111,346.3 	"
      />
      <polygon class="svg-shoes-lace-fill" points="98,352.1 129.1,363.8 135.4,360.2 106,348.8 	" />
      <polygon
        class="svg-shoes-lace-fill"
        points="135.4,368.5 131.8,335.5 138.4,332.4 143.2,366.7 	"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="166.2,356.3 129.1,337 140.1,331.8 173.7,350.4 	"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="159.6,321.4 166.2,356.3 173.7,352.7 166.6,313.8 	"
      />
      <polygon
        class="svg-shoes-lace-fill"
        points="201.2,338.4 205.7,332.4 171.4,310.5 165.4,315.1 	"
      />
    </g>
  </svg>
</template>
