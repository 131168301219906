<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="181 173 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path class="svg-shoes-sole-fill" d="M280.6,223.5c0,0,0.6,1.3,0.3,2.7c-0.3,1.4-0.2,2.5-2.3,3.7s-3.6,1-3.7,2.6   c-0.1,1.5-0.1,2.1-0.3,2.6c-0.2,0.6-0.4,1-1.4,1c-1,0-1.9,0.3-2.1-0.9c-0.1-0.7,0.1-1.2-1.1-1s-1.4,0.1-1.8,0   c-0.3-0.1-1.1-0.5-1.1-1.8c-1.8-0.1-3.2-0.1-4.2,0.3s-1.6,1.5-1.8,2.6s-0.3,1.7-1.1,1.7c-0.8,0-1.9,0.1-2.3,0.1s-1.1,0.2-1.4-0.8   c-0.2-1.1-0.5-1.3-0.5-1.3l-1.7,0.1c0,0-0.7-0.7-0.6-1.8c-1.8-0.2-3.9-0.3-5.4-0.3s-2.1,0-4.4,0.2c-2.2,0.2-11,1-12.1,1.2   c-1.2,0.4-1.8,0.5-2,0.4c-0.2,0-1.4-0.1-2.1,0c-0.6,0-1.4,0-1.6,1.2c-0.2,1.2-0.1,1.4-0.3,1.7c-0.2,0.3-0.8,0.7-1.5,0.8   c-0.6,0-1.1,0-1.5,0c-0.4,0-0.6-0.8-0.7-1.1c-0.1-0.3-0.3-1.8-2.7-1.9s-3.9-0.2-5.6,0c-1.8,0.2-2.5-0.1-3.1,0.1   c-0.6,0.2-0.8,0.2-1.2,1.2c-0.4,1-0.5,1.6-0.6,2.1c-0.1,0.4-0.8,0.5-1,0.5s-1.4-0.2-1.8-0.2c-0.4,0-1-0.5-1.3-1.3   c-0.4-0.8-0.7-2.3-1.5-2.7c-0.8-0.4-4.1-0.6-5.1-0.9c-1-0.2-3.1-0.9-3.9-0.9c-0.8,0-1.5-0.3-2,0.9c-0.5,1.2-1.1,1.7-2.3,1.4   c-1.2-0.3-1.2-0.4-1.4-0.5s-0.7-0.4-0.6-1.2c0.1-0.8,0-2.4-1.8-3c-1.8-0.6-4.4-2.1-5-3.3c-0.2-1-0.3-1.7-0.1-2.2   c1.3-0.7,6.2-1.3,6.2-1.3L280.6,223.5z"/>
      <path class="svg-shoes-color-fill" d="M271.6,187.1c0,0-3.2,4.3-11.5,4.9c-8.3,0.7-8.5-0.8-8.5-0.8s-1.7,1.7-2.1,2.3   c-0.3,0.6-1.5,2-3.5,2.7c-1.3,0.7-2.9,2.3-3.7,2.7c-1.1-0.3-2.3-0.7-3.5,0c-0.3,0.4-0.4,1.2-0.8,1.4c-0.4,0.2-1.2,0.4-1.5,0.8   c-0.2,0.4-0.8,0.4-1.1,0.4s-1.6-0.2-2.4,0.8c-0.5,0.7-0.8,1.2-1.8,1.5c-1,0.3-1.8,0-2.7,0.4c-0.9,0.4-1.9,1.2-2.2,2   c-0.4,0.4-1.5,0.4-2,0.3c-0.5-0.1-3,0.3-3.4,0.8c-0.2,0.4,0,0.7,0,0.7s-3.6,1-4.5,1.4c-0.8,0.4-1.2,0.6-1.2,0.6s0-0.8-0.8-0.5   c-0.8,0.3-1,0.7-1.5,1c-0.6,0.3-1.2,0.8-2,1c-0.9,0.2-1.7,0.2-2.9,0.5c-1.2,0.4-1.2,0.6-1.4,0.8c-0.3,0.2-0.6,0.2-1.1,0.2   c-0.5,0-2,0.2-2.4,0.6c-0.4,0.3-1.1,0.4-1.6,0.4c-0.5,0-1.5,0.3-1.8,0.6c-0.3,0.3-1.2,0.3-1.5,0.4c-0.4,0.1-1.4,0.1-1.9,0.3   c-0.6,0.2,0,0.4-1.6,0.5c-1.6,0.1-1.8,0.2-2.2,0.5c-0.4,0.3-0.6,0.5-1.5,0.5c-0.8,0-1.2,0-1.8,0.3c-0.6,0.4-0.4,0.4-1.6,0.7   c-1.2,0.2-3.9,1.6-5.4,3.6c-1.5,2.1-1.2,3.6-1,3.8c0.3,0.2,2.1,1.7,5,2.7c2.9,1,5.4,2.2,8.4,3s3.5,1.2,7.8,1.2s5.8,0,9.2,0.6   s7-0.1,9.2-0.6c2.1-0.5,8.9-1.8,14.7-1.1c4.8,0.1,15.2-1.6,19.4-2.5c4.1-0.8,4.6-1.2,7.1-0.9c2.5,0.3,5.8-1.9,8.1-3.4   c1-0.8,0.6-0.9,2.7-0.9c2.1,0,5.9,0.5,6.7,0.4s1.3-0.1,1.4-1.5c0.1-1.4,0.3-4.7,0.2-5.6s-0.3-1.3-0.3-2.2s-0.8-2.9-1.1-3.2   c-0.3-0.3-0.3-1.2-0.5-1.7c-0.2-0.5-0.7-2.3-1.1-2.6c-0.4-0.3-0.6-1.3-0.7-1.8s-0.8-1.9-1-2.2s-0.2-0.8-0.5-1.1s-1.6-2.2-1.9-3.7   c-0.3-1.5-0.8-3.3-0.7-5.2C273.4,191.2,273.2,185.9,271.6,187.1z"/>
      <path class="svg-shoes-lace-fill" d="M245.8,202.9l-2.7-4.5l-0.8,0.5c0,0,0.1,2.4,2.2,4.4C245.4,203.1,245.8,202.9,245.8,202.9z"/>
      <path class="svg-shoes-lace-fill" d="M241.6,199c0,0-3.7,1.4-1.9,5.6c0.2,0.3-0.4,1.1-0.9,1.1c-0.5,0-1.1,0-1.2-1   c-0.1-1-0.1-3.6,0.4-4.4c0.5-0.8,0.7-1.7,2-1.8C241.3,198.5,241.6,199,241.6,199z"/>
      <path class="svg-shoes-lace-fill" d="M235.6,201.6c0,0-2.8,1.5-1.8,4.8c0,0.7-0.1,1.5-0.9,1.5c-0.8,0-1.3-0.7-1.4-1.3   c0-0.6,0.5-2.8,1-3.5C233,202.4,232.7,201.6,235.6,201.6z"/>
      <path class="svg-shoes-lace-fill" d="M230.5,204c0,0-2.8,1.4-2.7,3.8c0.1,2.4-0.7,2-1.1,2c-0.4-0.1-1-0.6-0.8-1.6s0.4-2.3,1.3-2.9   C228,204.6,228.5,203.8,230.5,204z"/>
      <path class="svg-shoes-lace-fill" d="M224.6,206.6c0,0-2.7,1.3-2.6,3.8c-0.1,1.2-1,1.8-1.7,1.7s-0.4-1.5-0.4-2s0.9-2,0.9-2   S219.6,206.5,224.6,206.6z"/>
      <path class="svg-shoes-lace-fill" d="M215.3,210.1c0,0-0.6,1.5,0,2.9c0.2,0.7-0.1,1-0.7,1.2c-0.5,0.1-1.3-0.5-1.5-1.5s0-2.4,0.2-2.6   c0.2-0.2,1.2-0.9,1.6-0.6C215.2,209.8,215.4,209.6,215.3,210.1z"/>
    </g>
  </svg>
</template>
