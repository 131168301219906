<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewbox="0 0 100.25 87"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg" transform="translate(-328,-569)">
      <path class="svg-shoes-sole-fill" d="M425.8,621.8c0,0,0.7,0.4,0.3,1.7c-0.4,1.2-0.9,2.8-0.9,3.4c-0.1,0.6,0.2,1.4,0.3,1.7   c0.1,0.3,1.5,5,1.5,5s1,0.4,0.8,1.5c0.4,1.1,0.7,2.2-1.3,2.8c-2.1,0.6-12.8,1.4-18.7,1.3s-14.1-0.8-16.5-0.8s-10.7,0.1-13.4,0.5   c-2.7,0.3-9.9,1-14.5,1c-1.8,0-2.3-0.2-2.3-0.2l-0.5-1h-0.4l-0.4,1.2c0,0-12.4-0.3-16.5-0.7c-4.1-0.4-10.1-1.4-13.8-3.1   c-1.4-0.9-1.9-1.8-1-3.1c0.3-0.9,0.8-1.3,1.2-1.4s70.8-7,70.8-7L425.8,621.8z"/>
      <path class="svg-shoes-color-fill" d="M419.5,598.4c0,0-0.3-0.8-0.9-0.8c-0.6,0-0.5-0.9-1.3-0.6c-0.8,0.3-2.6,1.1-3.5,1.7   c-1,0.7-2.3,2-2.9,4.5c-0.7,2.5-3.8,3.8-6.1,3.8s-5-0.9-8.1-2.9c-0.3-1.1-0.5-1.8-0.8-2.2c-0.3-0.3-0.7-0.8-0.7-1.4   c-0.1-0.5-1.4-1.4-1.8-1.8c-0.4-0.4-1.7-1.2-2.4-1.9c-0.7-0.7-1.1-1.2-2.4-0.7c-1.4,0.6-1.9,1.2-2.3,1.6c-0.4,0.4-1.2,0.9-1.5,0.9   c-0.3,0-1.1,0.2-1.5,0.5c-0.4,0.4-1,0.5-1.2,0.5s-1.9-2.7-2.5-3.1c-0.5-0.4-1.1-0.4-1.6,0c-0.6,0.4-1,0.8-1,1.2   c-1.1,0.7-3.8,3-4.2,3.4c-1.1,0.4-2.2,1.6-2,2.4c0.5,1.2,1.9,3.3,1.9,3.3s-3.1,0.4-4.1,1c-1,0.6-2.1,2.3-3.4,2.8s-4.2,1.8-4.8,2.4   c-0.7,0.5-1.2,1-1.7,1.4s-2.1,1.3-3.3,1.7s-2.5,0.9-2.8,1.1c-0.3,0.2-0.7,0.7-1.2,1.3c-0.5,0.6-2.8,2.8-6.5,3.8   c-3.7,1.1-6.2,1.8-7.4,1.8c-1.2,0-5.1,0.2-6.9,4.1c-0.7,1.8-0.6,2.8-1,3.1s0.1,2.8,1,3.1c0.9,0.3,10.2,3,21.9,2.8   c9.2-0.2,15.4-1.1,28.2-4.8c12.4-3.7,18-6.5,25.9-5.7c7.9,0.8,16.8,3.6,19.1-5c0.4-6-1.3-11.6-2.2-13.5   C422.9,606.6,419.5,598.4,419.5,598.4z"/>
      <path class="svg-shoes-lace-fill" d="M379.3,596.5l7.6,10.8c0,0,0.6,1.1,0.6,1.6c0,0.5,0,4.1,0,4.1s-0.5,1.4-1.1,1.8   c-0.6,0.4-2.1,1.4-2.1,1.4s-1,0.4-2.7,0s-2.3-0.6-2.3-0.6s-1.9-0.7-2.3-1.3c-0.3-0.7-6.2-10.5-6.2-10.5s-0.1-0.7,0.7-1.5   c0.7-0.6,1.3-0.8,1.3-0.8s3.5-3.1,4.2-3.4C377.1,597.4,377.6,596.5,379.3,596.5z"/>
    </g>
  </svg>
</template>
