<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="-75 198 460.5 398"
    style="enable-background:new -75 198 460.5 398;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-color-fill"
        d="M191.2,288.7c0.2-0.1,0.3-0.4,0.3-0.6c0-0.4-0.1-0.8-0.3-1.2c-0.2-0.4-0.5-0.7-0.7-1
		c-0.3-0.3-0.5-0.8-0.9-0.9c-1.9-0.7-4-1.5-5.2-3.2c-1.2-1.6-1.1-4.5,0.7-5.4c1.3-0.6,3-0.2,4.2-1c0.8-0.5,1.1-1.5,1.5-2.3
		c1.6-4.4,3.2-8.9,4.8-13.2c0.1-0.4,0.3-0.8,0.2-1.2c-0.3-0.9-1.6-0.9-2.6-1c-2.8-0.4-4.4-3.5-4.1-6.2c0.3-2.8,1.8-5.2,3.5-7.4
		c1.1-1.7,2.3-3.4,3.5-5.1c3.7-5.3,7.2-10.6,10.9-15.8c3.6-5.3,7.4-10.7,13-13.6c5.4-2.9,12-3.1,17.6-0.5c-0.4,2-1.3,4-2.6,5.5
		c3.3,0.8,6.5,1.6,9.8,2.4c10.6,2.6,20.9,4.1,31.5,6.6c17.2,4.3,32.9,12.9,48.4,21.5c4.8,2.6,9.6,5.3,13.4,9.3
		c4.9,4.9,7.8,11.6,13.3,15.8c2.2,1.7,4.8,3,6.5,5.2c1.9,2.8,2.2,6.3,2.3,9.6c0.2,5,0.5,10.1,0.7,15.1c0.4,7.8-0.1,14.6,0.1,22.4
		c0.1,4.4,0.2,8.9,1,13.1c0.7,4.2,2.1,8.3,3.3,12.4c2.1,7.9,3.5,16.1,4.8,24.1c2,12.7,4.2,25.4,6.2,38.1c0.7,4.7,2.1,7.7,3.4,12.3
		c8.3,29.8,6.1,62.5-6,91.1c2.3,1.7,3.1,4.1,3.8,6.8c2,7.5,4.2,15.2,5.1,22.9c0.5,4.1,0.7,8.3,0.7,12.3c-4.2,4.4-10.3,6.8-16.1,8.7
		c-2.6,0.8-5.5,1.4-8.3,1.2c-1.5-0.1-3.1-0.5-4.5-0.8c-4.1-1-8.3-1.9-12.3-3c-2,3.3-4.7,5.8-5.3,9.7c-5.8-0.3-12.5-1.3-18.2-0.3
		c-2.2-1.8-5.4-4-7.6-5.8c-1.8-1.5-5.4-2.1-7.1-0.5c-2.3,2.2-1.6,5.6-2.5,8.7c-7.3,0.6-14.6,0-21.9,0.7c-0.2-2.8-0.6-5.6-0.9-8.4
		c-3.8,0.4-8.1,0.6-11.9,1c-2.6,3.1-4.8,6.6-6.7,10.2c-5.8,0-11.4-0.3-17.2,0.5c-1.9-1.6-2.9-3.1-3.5-5.5c-1-3.7,1.4-7.4,1.7-11.2
		c-15.1-5-32.1-6.2-47.1-0.8c0,0.8-0.5,0.5-0.5,1.2c-0.1,1.6-0.2,3.6-1.4,4.7c-0.7,0.7-1.8,1-2.9,1.2c-3.7,0.9-7.2,1.9-10.9,2.9
		c-2.5,0.7-5.3,1.3-7.8,0.9c-2.2-0.4-4.3-1.6-6.5-1.5c-0.2,1.3-0.4,2.5-0.5,3.9c0,0.3-0.1,0.7-0.3,0.9c-0.2,0.2-0.5,0.4-0.9,0.4
		c-5.4,1.6-10.7,3.3-16.1,4.9c-4.8-1.5-9.8-3-14.8-3.7c0.4,2.3,1,5.4,1,7.7c-3,1-5.9,0.9-8.9,1.9c-3.1,1-6.2,2.1-9.5,1.5
		c-2.5-0.4-4.7-1.9-6.8-3.3c-2.4-1.6-5.2-4-4.6-6.8c-2.9,0.4-5.1-0.3-7.9,0.2c-0.3,3-0.6,5.8-0.8,8.8c-3.8-0.7-7.5-1.3-11.4-1
		c-2.8,0.2-5.8,0.8-8-0.8c-2.9-2.1-2.6-6.8-5.5-8.9c-2.4-1.7-6.4-2.3-9.5-2c-0.2,3.2-0.5,6.3-0.7,9.5c-6.6-1.1-13.4-1.4-20.2-0.9
		c-0.5-2.5-1-5.1-1.5-7.5c-0.1-0.7-0.3-1.3-0.7-1.9c-0.6-0.8-1.7-1.2-2.8-1.5c-2.5-0.7-5.2-1.3-7.8-1.8c0.1,2-0.1,4.2-0.6,6.2
		c-6.9,1.2-15.3,0-21.3-3.8c-0.2-2.5-1.6-6.8-3.3-8.9c-4.6-5.6-15-7.3-20.8-3.1c-3.9-2.9-9.9-6.6-14.3-8.8c-5-2.5-11.3-6.8-16-9.9
		c-4.9-3.3-9.9-8-14.6-11.5c-5.5-0.5-9.7-8.3-7.9-13.5c-1.1-4.7,2.3-10.1,2.4-14.9c0-1.4-0.3-2.9-0.5-4.3c-0.5-4.2,0.1-8.6,1.8-12.5
		c5.5-12.2,10.8-26.3,22.6-32.5c5.2-2.8,11.1-3.7,16.8-4.6c18.3-3,36.6-5.9,54.9-8.8c4.3-0.7,7.8-3.6,11.2-6.4
		c2.9-2.4,5.6-5.3,9.2-6.4c3.1-0.9,6.2-0.3,9.4,0.3c3.1,0.6,6.2,1.2,8.5,3.4c4.6-6.7,13-9.8,20.9-12.2c7.8-2.4,16-4.1,22.9-8.4
		c3-1.8,5.7-4.1,9-5.4c4-1.6,8.6-1.6,12.5-3.1c4.9-1.8,8.9-5.9,10.5-10.8c3.3-9.7,15.2-14.2,23.7-19.8c1.5-0.9,3.1-1.9,4.2-3.3
		c1-1.1,1.6-2.5,2.2-4c1.6-3.9,3.4-7.6,5-11.5c0.6-1.5,1.3-3.1,1.2-4.7c-0.2-2.5-2.2-5.1-1.3-7.4c1-2.6,5.2-3.7,4.9-6.5
		c-0.1-1-0.8-1.8-1-2.9c-0.5-3.1,4-4.9,4-7.9c0-3-4.1-4.4-4.8-7.2c-0.4-1.3,0-2.6,0.4-4c2.4-8.5,5.5-17.7,13.1-22
        "
      />
      <path
        class="svg-shoes-sole-fill"
        d="M373.2,513.4c2.3,1.7,3.1,4.1,3.8,6.8c2,7.5,4.2,15.2,5.1,22.9c0.5,4.1,0.7,8.3,0.7,12.3
		c-4.2,4.4-10.3,6.8-16.1,8.7c-2.6,0.8-5.5,1.4-8.3,1.2c-1.5-0.1-3.1-0.5-4.5-0.8c-4.1-1-8.3-1.9-12.3-3c-2,3.3-4.7,5.8-5.3,9.7
		c-5.8-0.3-12.5-1.3-18.2-0.3c-2.2-1.8-5.4-4-7.6-5.8c-1.8-1.5-5.4-2.1-7.1-0.5c-2.3,2.2-1.6,5.6-2.5,8.7c-7.3,0.6-14.6,0-21.9,0.7
		c-0.2-2.8-0.6-5.6-0.9-8.4c-3.8,0.4-8.1,0.6-11.9,1c-2.6,3.1-4.8,6.6-6.7,10.2c-5.8,0-11.4-0.3-17.2,0.5c-1.9-1.6-2.9-3.1-3.5-5.5
		c-1-3.7,1.4-7.4,1.7-11.2c-15.1-5-32.1-6.2-47.1-0.8c0,0.8-0.5,0.5-0.5,1.2c-0.1,1.6-0.2,3.6-1.4,4.7c-0.7,0.7-1.8,1-2.9,1.2
		c-3.7,0.9-7.2,1.9-10.9,2.9c-2.5,0.7-5.3,1.3-7.8,0.9c-2.2-0.4-4.3-1.6-6.5-1.5c-0.2,1.3-0.4,2.5-0.5,3.9c0,0.3-0.1,0.7-0.3,0.9
		c-0.2,0.2-0.5,0.4-0.9,0.4c-5.4,1.6-10.7,3.3-16.1,4.9c-4.8-1.5-9.8-3-14.8-3.7c0.4,2.3,1,5.4,1,7.7c-3,1-5.9,0.9-8.9,1.9
		c-3.1,1-6.2,2.1-9.5,1.5c-2.5-0.4-4.7-1.9-6.8-3.3c-2.4-1.6-5.2-4-4.6-6.8c-2.9,0.4-5.1-0.3-7.9,0.2c-0.3,3-0.6,5.8-0.8,8.8
		c-3.8-0.7-7.5-1.3-11.4-1c-2.8,0.2-5.8,0.8-8-0.8c-2.9-2.1-2.6-6.8-5.5-8.9c-2.4-1.7-6.4-2.3-9.5-2c-0.2,3.2-0.5,6.3-0.7,9.5
		c-6.6-1.1-13.4-1.4-20.2-0.9c-0.5-2.5-1-5.1-1.5-7.5c-0.1-0.7-0.3-1.3-0.7-1.9c-0.6-0.8-1.7-1.2-2.8-1.5c-2.5-0.7-5.2-1.3-7.8-1.8
		c0.1,2-0.1,4.2-0.6,6.2c-6.9,1.2-15.3,0-21.3-3.8c-0.2-2.5-1.6-6.8-3.3-8.9c-4.6-5.6-15-7.3-20.8-3.1c-3.9-2.9-9.9-6.6-14.3-8.8
		c-5-2.5-11.3-6.8-16-9.9c-4.9-3.3-9.9-8-14.6-11.5c-5.5-0.5-9.7-8.3-7.9-13.5c-1.1-4.7,2.3-10.1,2.4-14.9c0-1.4-0.3-2.9-0.5-4.3
		c-0.5-4.2,0.1-8.6,1.8-12.5c0.8-1.9,2-4.3,2.9-6.2c0.8-1.9,1.6-3.8,2.3-5.6c0.6-1.6,1.7-3.2,2.4-4.7c0.8-1.6,1.7-3.2,2.5-4.9
		c0.6-1.1,1.9-1.7,2.6-2.9c0.8-1,1.8-2.3,2.6-3.3c1.2-1.4,2.5-2,4.1-3.1c1.4-1,3.4-1.8,4.9-2.8c1.2-0.7,3.2-1.1,4.5-1.6
		c6.5-2.9,13.3-2.3,20.5-3.1c-0.3,4.4-0.9,8.8-1.8,13c-0.2,0.9-0.7,1.6-1.1,2.4c-0.3,0.6-0.5,1.2-1,1.8c-0.6,0.8-1.5,1.2-2.4,1.7
		c-1.1,0.5-2.3,1-3.5,1.5c-2.3,0.9-4.4,1.9-6.1,3.8c-1.5,1.6-2,3.7-3.1,5.6c-1.1,2.2-1.6,5-1,7.4c0.7,3.1,2.8,6.2,5.2,8
		c1.5,1.1,3.3,2,4.9,3c0.3,0.2,0.3,0.1,0.5,0.3c3.2,1.7,6.5,3.3,9.9,4.3c1.4,0.4,3.1,1.1,4.4,1.6c2.2,0.9,4.7,1.1,6.8,2.3
		c1.8,1,3.5,1.3,5.6,1.6c2.5,0.3,5.1,1.3,7.6,1.9c3,0.7,5.8,0.4,8.9,0.6c3.7,0.3,6.8,1,10.5,1.4c3.1,0.3,6.1,1,9.2,1.6
		c4.1,0.7,8.4,1.1,12.4,1.5c3.6,0.4,7.1,1.4,10.7,1.5c5.4,0.1,11.1,1.7,16.5,1.8c5.3,0.1,10.5,0.4,15.8,0.2
		c4.7-0.2,9.4-0.1,14.1-0.1c5,0,10.8,0.1,15.8,0.1c5.6,0,10.9-0.4,16.5-0.5c-0.3-0.1-0.1-0.3-0.4-0.4c5.3,0.5,10.7,0.8,16,1
		c4.1,0.1,6.8-0.8,10.9-0.9c3.8-0.1,7.2-0.4,11-0.6c7.4-0.4,14-1.2,21.4-1.5c2-0.1,3.1,0.1,5.1-0.1s4.1-0.9,6.1-1.1
		c2.6-0.2,4.3-0.3,6.8-0.8c2-0.4,3.9-0.2,5.9-0.2c1.4,0,1.3,0.4,2.8,0.2c-0.1,0.2,0,0.6-0.1,0.8c1.9-2.5,4-4.9,6.2-7.2
		c-0.1,0.3,0.8-0.7,0.7-0.4c2.3-2,4.5-4.1,6.7-6.2c2.5-2.5,5.2-5.3,7.9-7.5c3-2.4,4.8-4.4,7.3-7.1c2.9-3.2,5.3-5,8.7-7.6
		c3.2-2.4,5.8-4.6,9.1-6.7c-0.2,0.2-1.1,0.4-1.4,0.6c3.2-1.5,6.1-3.3,9.2-5.1c3.3-2,6.7-3.8,10.2-5.4c2.9-1.3,6.3-2.8,9.2-3.9
		c3.2-1.2,5.3-2.3,8.1-4c7.6-4.3,16.3-6.3,24.1-10.1c3.2-1.5,6.3-3.4,9.2-5.3c3.2-2,8.3-4.2,11.5-6c3.3-1.9,6.9-4.7,10.4-6.2
		c2.8-1.2,3.9-2.3,5.9-4.6c1.2-1.3,3.5-3.5,5.1-4.4c0.1,0.1,0.5-0.5,0.5-0.6s0.1-0.2,0.1-0.3c1.4-1.1,2.9-2.1,4.2-3.3
		c1.3-1,0-2,0.3-3.6c0.1-0.7,0.6-1.7,1-2.2c1.7,10.7,6.2,20.9,8.1,31.5c2,10.6,1.7,21.6,1.4,32.4c-0.2,6.3-1.7,12.7-3.2,18.9
		c-1.1,4.9-2.2,9.9-3.4,14.8c-1,4.5-2,9.1-4.6,12.9"
      />
      <polygon class="svg-shoes-lace-fill" points="40.4,453.5 52.9,424.9 61.6,427.3 44,463.1 37.9,458 	"/>
      <polygon class="svg-shoes-lace-fill" points="63.6,455.2 67.6,449.4 53.2,431.3 49.7,437.2 	"/>
      <polygon class="svg-shoes-lace-fill" points="87.5,444 91.4,448.1 104,409.8 97.4,412.4 	"/>
      <polygon class="svg-shoes-lace-fill" points="117.5,431.3 121.4,426.5 102.8,413.4 100.9,419 	"/>
      <polygon class="svg-shoes-lace-fill" points="130.1,398.9 124.5,419.3 130.1,424.9 136.7,393.3 	"/>
      <polygon class="svg-shoes-lace-fill" points="155.4,407.3 132.3,405.1 133.6,400.7 155.5,400.7 	"/>
      <polygon class="svg-shoes-lace-fill" points="162.8,368.9 162.8,396.9 168,400.7 168,365.9 	"/>
      <polygon class="svg-shoes-lace-fill" points="186.3,399.5 156.7,373.7 162.8,368.9 186.3,392 186.3,400.7 	"/>
      <path
        class="svg-shoes-lace-fill"
        d="M197.5,378.6c0,0-21.4-19.4-19.9-37.5c0.3-2.3,8.7-2.5,8.7-2.5s-11,10,11.2,32.9
		C198,376.3,197.5,378.6,197.5,378.6z"
      />
      <polygon class="svg-shoes-lace-fill" points="202.4,338.6 174.1,341.3 174.9,336.8 178.6,333.1 200.9,332 	"/>
      <polygon class="svg-shoes-lace-fill" points="208,320.7 195.8,305 195.8,294.6 210.7,313.6 	"/>
    </g>
  </svg>
</template>
