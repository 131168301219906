<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px" y="0px"
    viewBox="0 0 62.1 66.3"
    style="enable-background:new 0 0 62.1 66.3;"
    xml:space="preserve"
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        class="svg-shoes-sole-fill"
        d="M4.6,58c0,0-1.6,0.2-1.6,1.5c0,1.3-0.3,2.2,1.3,2.6c1.6,0.4,6.1,1.2,9.7,1.5s11.2,2.4,24.7-1.7
	c2.5-1.4,5-4.2,5.5-4.7c0.4-0.5,1.2-1.3,2.6-1.1c1.4,0.2,2,0.9,3.5,0.9c1.5-0.1,6.6-1.3,7.8-2s1.3-0.9,1.4-1.8
	c0.2-0.9-0.3-3.4-0.3-3.4s0.7-1,0.3-1.8c-0.3-0.9-0.7-0.6-0.7-0.6L41.3,53l-27,5L4.6,58z"
      />
      <path
        class="svg-shoes-color-fill"
        d="M4.6,58c0,0-3.9-3.5-0.5-7c1.1-1.3,3.6-2.1,5.4-2.9c1.8-0.8,2.6-1.1,5.1-3.1s9-5.7,11.5-7.8
	c2.5-2.1,6.4-5.9,6.7-13.6c0.3-7.7,0.7-12,1.2-13.1s2.1-5.4,2.5-7s0.7-2,0.7-2s8.1,1.6,14.4,0.8c0.5,0,0.4,0.9,0.3,1.2
	c-0.1,0.3-0.6,1.7-0.4,2C51.6,5.9,52,5.8,52,6.6s0,1.9,0.3,2.5c0.2,0.6,0.4,0.8,1.4,0.9c1,0,2.8,0.7,3.5,3.6c0.2,1.6-0.3,8,0.4,14.3
	s0.2,6.5,0.6,9.1s1.1,4,0.9,7.4c-0.2,3.4-0.2,3.3-1.5,4c-1.3,0.8-7,3.1-9.1,3.7c-2.1,0.7-5.2,2.5-9.3,4.7c-4.7,1.7-11.8,3.4-20.6,3
	S5.6,58.8,4.6,58z"
      />
      <path
        class="svg-shoes-lace-fill"
        d="M41.9,7.5c0,0-4.7,7-4.8,17.2c-1.1,7.2-6.8,15.4-17.4,21.8c-1.3,0.6-4.1,0-4.1,0
	s-0.5,0.2,3-2.2s15-8.5,16.6-20.3C36,12.2,39.6,6.5,39.6,6.5L41.9,7.5z"
      />
    </g>
  </svg>
</template>
