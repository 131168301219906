<template>
  <component :is="iconComponent"></component>
</template>

<script setup>
import RunningShoes from './RunningShoes.vue';
import ApproachShoes from './ApproachShoes.vue';
import BadmintonShoes from './BadmintonShoes.vue';
import BaseballCleats from './BaseballCleats.vue';
import BasketballShoes from './BasketballShoes.vue';
import ClimbingShoes from './ClimbingShoes.vue';
import ClimbingFootwear from './ClimbingFootwear.vue';
import HikingFootwear from './HikingFootwear.vue';
import HikingShoes from './HikingShoes.vue';
import HikingBoots from './HikingBoots.vue';
import TrainingShoes from './TrainingShoes.vue';
import Sneakers from './Sneakers.vue';
import GolfShoes from './GolfShoes.vue';
import WrestlingShoes from './WrestlingShoes.vue';
import TrackAndFieldShoes from './TrackAndFieldShoes.vue';
import TrailRunningShoes from './TrailRunningShoes.vue';
import CyclingShoes from './CyclingShoes.vue';
import CrossfitShoes from './CrossfitShoes.vue';
import WalkingShoes from './WalkingShoes.vue';
import WeightliftingShoes from './WeightliftingShoes.vue';
import SoccerCleats from './SoccerCleats.vue';
import TennisShoes from './TennisShoes.vue';
import VolleyballShoes from './VolleyballShoes.vue';
import FootballCleats from './FootballCleats.vue';
import HikingSandals from "~/components/icons/categories/HikingSandals";
import MountaineeringBoots from "~/components/icons/categories/MountaineeringBoots";

const icons = {
  'running-shoes': markRaw(RunningShoes),
  'trail-running-shoes': markRaw(TrailRunningShoes),
  'badminton-shoes': markRaw(BadmintonShoes),
  'approach-shoes': markRaw(ApproachShoes),
  'baseball-cleats': markRaw(BaseballCleats),
  'basketball-shoes': markRaw(BasketballShoes),
  'climbing-shoes': markRaw(ClimbingShoes),
  'climbing-footwear': markRaw(ClimbingFootwear),
  'hiking-footwear': markRaw(HikingFootwear),
  'hiking-shoes': markRaw(HikingShoes),
  'hiking-boots': markRaw(HikingBoots),
  'hiking-sandals': markRaw(HikingSandals),
  'mountaineering-boots': markRaw(MountaineeringBoots),
  'training-shoes': markRaw(TrainingShoes),
  sneakers: markRaw(Sneakers),
  'golf-shoes': markRaw(GolfShoes),
  'wrestling-shoes': markRaw(WrestlingShoes),
  'track-and-field-shoes': markRaw(TrackAndFieldShoes),
  'track-spikes-and-xc': markRaw(TrackAndFieldShoes),
  'track-spikes': markRaw(TrackAndFieldShoes),
  'cross-country-shoes': markRaw(TrackAndFieldShoes),
  'cycling-shoes': markRaw(CyclingShoes),
  'crossfit-shoes': markRaw(CrossfitShoes),
  'walking-shoes': markRaw(WalkingShoes),
  'weightlifting-shoes': markRaw(WeightliftingShoes),
  'soccer-cleats': markRaw(SoccerCleats),
  'tennis-shoes': markRaw(TennisShoes),
  'volleyball-shoes': markRaw(VolleyballShoes),
  'football-cleats': markRaw(FootballCleats),
};

const props = defineProps({
  slug: String,
})

const iconComponent = computed(() => icons[props.slug] || icons['running-shoes']);

</script>